import React, { useEffect, useState,useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';
import ExportToExcel from '../../components/ExcelFile';
import PdfFile from '../../components/PdfFile';

const TrainSpeedBreakup = () => {
   
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(false);
    const gridApiRef = useRef(null);

    useEffect(() => {
      setLoading(true);
        // Fetch data from the API
        axios.get(`${process.env.REACT_APP_REPORT_SERVICE_ADDRESS}/TrainSpeedBreakup`)
          .then(response => {
            // Set the fetched data to the state
            setRowData(response.data);
            setLoading(false);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
            setLoading(false);
          });
      }, []);    

      const columnDefs = [         
        { headerName: 'TrainNumber', field: 'trainNumber', filter: true},      
        { headerName: 'Speed', field: 'trainSpeed'},
        { headerName: 'Distance', field: 'trainDistance'},
        { headerName: 'Travel Time', field: 'travelTime'},
        { headerName: 'Origin', field: 'origSttn'},
        { headerName: 'Destination', field: 'dstnSttn'},
        { headerName: 'Frequency', field: 'frequency'},
        { headerName: 'Owning Railway', field: 'owningZone'},
        { headerName: 'Stoppage Count', field: 'stoppageCount'},
        { headerName: 'Allowances', field: 'allowances'},
        { headerName: 'CR', field: 'speedCR'},
        { headerName: 'ECoR', field: 'speedECOR'},
        { headerName: 'ECR', field: 'speedECR'},
        { headerName: 'ER', field: 'speedER'},
        { headerName: 'KR', field: 'speedKR'},
        { headerName: 'NCR', field: 'speedNCR'},
        { headerName: 'NER', field: 'speedNER'},
        { headerName: 'NFR', field: 'speedNFR'},
        { headerName: 'NR', field: 'speedNR'},
        { headerName: 'NWR', field: 'speedNWR'},
        { headerName: 'SCR', field: 'speedSCR'},
        { headerName: 'SECR', field: 'speedSECR'},
        { headerName: 'SER', field: 'speedSER'},
        { headerName: 'SR', field: 'speedSR'},
        { headerName: 'SWR', field: 'speedSWR'},
        { headerName: 'WCR', field: 'speedWCR'},
        { headerName: 'WR', field: 'speedWR'},
    ];
       
    const onGridReady = (params) => {
      gridApiRef.current = params.api;
    };
  
    const adjustColumnWidths = () => {
      if (gridApiRef.current) {
        const allColumnIds = [];
        const columnDefs = gridApiRef.current.getColumnDefs();
        
        // Get all column ids
        columnDefs.forEach((column) => {
          allColumnIds.push(column.field);
        });
    
        // Iterate over each row to determine the maximum width for each column
        const maxWidths = {};
        rowData.forEach((row) => {
          allColumnIds.forEach((colId) => {
            const value = row[colId] ? String(row[colId]).length : 0;
            maxWidths[colId] = Math.max(maxWidths[colId] || 0, value);
          });
        });
    
        // Update column definitions with adjusted widths
        const updatedColumnDefs = columnDefs.map((column) => {
          const maxWidth = maxWidths[column.field] * 8 + 40; // Adjust based on your font size and padding
          return { ...column, width: maxWidth };
        });
    
        // Set the updated column definitions
        gridApiRef.current.setColumnDefs(updatedColumnDefs);
      }
    };
    
  
    const gridOptions = {
      getRowHeight: () => 20, // Set a fixed row height of 20 pixels
      // Other grid options...
    };

    const columnOrder = ['trainNumber', 'trainSpeed','trainDistance','travelTime','origSttn','dstnSttn','frequency','owningZone','stoppageCount',
                          'allowances','speedCR','speedECOR','speedECR','speedER','speedKR','speedNCR','speedNER','speedNFR','speedNR','speedNWR',
                          'speedSCR','speedSECR','speedSER','speedSR','speedSWR','speedWCR','speedWR'];

    const reorderedData = rowData.map(item => {
    const reorderedItem = {};
    columnOrder.forEach(key => {
      if (item.hasOwnProperty(key)) {
        reorderedItem[key] = item[key];
      }
    });
    return reorderedItem;
  });
    
  return (
    <div className="ag-theme-alpine" style={{ height: '650px'}}>
          
        <td>       
          <PdfFile pdfData={rowData} columnDefinition={columnDefs} columnOrder={columnOrder} fileName="TrainSpeedBreakup"/>

          <ExportToExcel excelData={reorderedData} fileName="example_data" />
        </td>
      
        {loading ? (        
        // Show a progress bar while loading data       
        <progress style={{ width: '100%', height: '30px',}} />
      ) : (
      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        //domLayout='autoHeight'
        // autoSizeColumns={true}
        // rowSelection="multiple"
        onGridReady={onGridReady}
        onFirstDataRendered={adjustColumnWidths}
        gridOptions={gridOptions}
      />
      )}
    </div>
  );

};

export default TrainSpeedBreakup;