import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';
import SelectDivision from '../../components/SelectDivision';
import SelectZone from '../../components/SelectZone';
import SelectStation from '../../components/SelectStation';
import ExportToExcel from '../../components/ExcelFile';
import PdfFile from '../../components/PdfFile';

const StationTimeTable = () => {
  const [rowData, setRowData] = useState([]);
  const [selectedZone, setSelectedZone] = useState(null);
  const [selectedDivision, setSelectedDivision] = useState(null);
  const [selectedStation, setSelectedStation] = useState(null);

  const handleSelectZone = (selectedZone) => {
    console.log("Selected Zone:", selectedZone.value);

    setSelectedZone(selectedZone.value);
  };

  const handleSelectDivision = (selectedDivision) => {
    console.log("Selected Division:", selectedDivision.value);
    setSelectedDivision(selectedDivision.value);
  };

  const handleSelectStation = (selectedStation) => {
    console.log("Selected Station:", selectedStation.value);
    setSelectedStation(selectedStation.value);
  };

  const handleButtonClick = () => {

    if (selectedStation) {
      axios.get(`${process.env.REACT_APP_REPORT_SERVICE_ADDRESS}/SttnTimetable/${selectedStation}`)
        .then(response => {
          // Set the fetched data to the state
          setRowData(response.data);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }
  };

  const columnDefs = [
    { headerName: 'Train Name', field: 'trainName' },
    { headerName: 'Train Type', field: 'trainType' },
    { headerName: 'Days of Service', field: 'dayService' },
    { headerName: 'Train Group', field: 'trainGroup' },
    { headerName: 'Train Direction', field: 'direction' },
    { headerName: 'WTT Arrival', field: 'wttArrival' },
    { headerName: 'WTT Departure', field: 'wttDeparture' },
    { headerName: 'PTT Arrival', field: 'pttArrival' },
    { headerName: 'PTT Departure', field: 'pttDeparture' },
    { headerName: 'WTT Stoppage', field: 'wttStoppage' },
    { headerName: 'PTT Stoppage', field: 'pttStoppage' },
  ];

  const columnOrder = ['trainName', 'trainType', 'dayService', 'trainGroup', 'direction', 'wttArrival', 'wttDeparture', 'pttArrival', 'pttDeparture', 'wttStoppage', 'pttStoppage'];

  const reorderedData = rowData.map(item => {
    const reorderedItem = {};
    columnOrder.forEach(key => {
      if (item.hasOwnProperty(key)) {
        reorderedItem[key] = item[key];
      }
    });
    return reorderedItem;
  });

  return (
    <div className="ag-theme-alpine" style={{ height: '650px'}}>
      <div>
        <table>
          <tr>
            <td style={{ width: '210px' }}>
              <SelectZone onSelectChange={handleSelectZone} />
            </td>
            <td style={{ width: '210px' }}>
              {selectedZone && <SelectDivision selectedValue={selectedZone} onSelectChange={handleSelectDivision} />}
            </td>
            <td style={{ width: '210px' }}>
              {selectedZone && selectedDivision && <SelectStation selectedValue={selectedZone} selectedValue1={selectedDivision} onSelectChange={handleSelectStation} />}
            </td>
            <td> <button onClick={handleButtonClick}>Fetch Data</button> </td>

            <td> <PdfFile pdfData={rowData} columnDefinition={columnDefs} columnOrder={columnOrder} fileName="StationTimeTable" /></td>

            <td> <ExportToExcel excelData={reorderedData} fileName="example_data" /> </td>
          </tr>
        </table>
      </div>

      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        autoSizeColumns={true}
        rowSelection="multiple"
      />
    </div>
  );
};

export default StationTimeTable;
