import React, { useEffect, useState,useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';
import ExportToExcel from '../../components/ExcelFile';
import PdfFile from '../../components/PdfFile';

const InfraZoneMaster = () => {

  const [rowData, setRowData] = useState([]);
  const gridApiRef = useRef(null);

  useEffect(() => {
    // Fetch data from the API
    axios.get(`${process.env.REACT_APP_INFRA_SERVICE_ADDRESS}/zonelist`)
      .then(response => {
        // Set the fetched data to the state
        setRowData(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);
 

  const columnDefs = [
    { headerName: 'Zone Code', field: 'zoneCode' },
    { headerName: 'Zone Name', field: 'zoneName' },
  ];

  const onGridReady = (params) => {
    gridApiRef.current = params.api;
  };

  const onFirstDataRendered = () => {
    if (gridApiRef.current) {
      gridApiRef.current.autoSizeAllColumns();
    }
  };

  const gridOptions = {
    getRowHeight: () => 20, // Set a fixed row height of 20 pixels
    // Other grid options...
  };

  const columnOrder = ['zoneCode', 'zoneName'];

  const reorderedData = rowData.map(item => {
    const reorderedItem = {};
    columnOrder.forEach(key => {
      if (item.hasOwnProperty(key)) {
        reorderedItem[key] = item[key];
      }
    });
    return reorderedItem;
  });

  return (
    <div className="ag-theme-alpine" style={{ height: '650px'}}>

      {/* <div style={{background:'red'}}> */}
        {/* <table>
          <tr>
            <td>  */}
              <PdfFile pdfData={rowData} columnDefinition={columnDefs} columnOrder={columnOrder} fileName="ZoneMaster" /> 
              {/* </td>
            <td>  */}
              <ExportToExcel excelData={reorderedData} fileName="ZoneMaster" /> 
              {/* </td>
          </tr>
        </table> */}
      {/* </div> */}

      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        //domLayout='autoHeight'
        autoSizeColumns={true}
        //rowSelection="multiple"
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        gridOptions={gridOptions}
      />
    </div>
  );

};

export default InfraZoneMaster;