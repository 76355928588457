import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';
import ExportToExcel from '../../components/ExcelFile';
import PdfFile from '../../components/PdfFile';

const DivisionTractionSummary = () => {
   
    const [rowData, setRowData] = useState([]);

    useEffect(() => {
        // Fetch data from the API
        axios.get(`${process.env.REACT_APP_INFRA_SERVICE_ADDRESS}/DvsnTracktionSummary`)
          .then(response => {
            // Set the fetched data to the state
            setRowData(response.data);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
      }, []);
    

      const columnDefs = [         
        { headerName: 'Zone', field: 'zonecode', filter: true},
        { headerName: 'Division', field: 'dvsncode', filter: true},      
        { headerName: 'Train Count', field: 'traincount'},
        { headerName: 'Distance', field: 'distance'},
        { headerName: 'Electrified', field: 'electrified'},
        { headerName: 'Electrified Percentage', field: 'electrifiedPercent'},
        { headerName: 'Non Electrified', field: 'nonElectrified'},
        { headerName: 'Non Electrified Percentage', field: 'nonElectrifiedPercent'},
    ];
       
    const columnOrder = ['zonecode','dvsncode', 'traincount', 'distance', 'electrified', 'electrifiedPercent', 'nonElectrified','nonElectrifiedPercent'];

    const reorderedData = rowData.map(item => {
      const reorderedItem = {};
      columnOrder.forEach(key => {
        if (item.hasOwnProperty(key)) {
          reorderedItem[key] = item[key];
        }
      });
      return reorderedItem;
    });    

  return (
    <div className="ag-theme-alpine" style={{ height: '650px'}}>
        <div>
        <table>
          <tr>
            <td> <PdfFile pdfData={rowData} columnDefinition={columnDefs} columnOrder={columnOrder} fileName="DivisionTractionSummary" /></td>

            <td> <ExportToExcel excelData={reorderedData} fileName="DivisionTractionSummary" /> </td>
          </tr>
        </table>
      </div>

      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        //domLayout='autoHeight'
        autoSizeColumns={true}
        rowSelection="multiple"
      />
    </div>
  );

};

export default DivisionTractionSummary;