import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';
import ExportToExcel from '../../components/ExcelFile';
import PdfFile from '../../components/PdfFile';

const NonStopLongRuntimeTrains = () => {
   
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      setLoading(true);
        // Fetch data from the API
        axios.get(`${process.env.REACT_APP_REPORT_SERVICE_ADDRESS}/NonStopLongRunTrain`)
          .then(response => {
            // Set the fetched data to the state
            setRowData(response.data);
            setLoading(false);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
            setLoading(false);
          });
      }, []);
    

      const columnDefs = [         
        
        { headerName: 'Train Number', field: 'trainNumber', filter: true},
        { headerName: 'Train Name', field: 'trainName', filter: true},      
        { headerName: 'Owning Railway', field: 'owningZone'},
        { headerName: 'Origin', field: 'origSttn'},
        { headerName: 'Destination', field: 'dstnSttn'},
        { headerName: 'Train Type', field: 'trainType'},
        { headerName: 'Section Code', field: 'journeySctnSttnCode'},
        { headerName: 'Section Name', field: 'journeySctnSttnName'},
        { headerName: 'Section Distance', field: 'journeySctnDistance'},
        { headerName: 'Section Duration', field: 'journeySctnDuration'},
        { headerName: 'Section Speed', field: 'journeySctnDistanceSpeed'},
    ];
       
    const columnOrder = ['trainNumber', 'trainName','owningZone','origSttn','dstnSttn','trainType','journeySctnSttnCode','journeySctnSttnName','journeySctnDistance',
                          'journeySctnDuration','journeySctnDistanceSpeed'];

    const reorderedData = rowData.map(item => {
    const reorderedItem = {};
    columnOrder.forEach(key => {
      if (item.hasOwnProperty(key)) {
        reorderedItem[key] = item[key];
      }
    });
    return reorderedItem;
  });

    
  return (
    <div className="ag-theme-alpine" style={{ height: '650px'}}>
        <div>  
        <td>       
          <PdfFile pdfData={rowData} columnDefinition={columnDefs} columnOrder={columnOrder} fileName="NonStopLongRuntimeTrains"/>

          <ExportToExcel excelData={reorderedData} fileName="example_data" />
        </td>
      </div>

      {loading ? (        
        // Show a progress bar while loading data       
        <progress style={{ width: '100%', height: '30px',}} />
      ) : (
      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        //domLayout='autoHeight'
        autoSizeColumns={true}
        rowSelection="multiple"
      />
      )}
    </div>
  );

};

export default NonStopLongRuntimeTrains;