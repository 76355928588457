const convertBinaryToDayAbbreviation = ({value }) => {

    const binaryString = String(value);

    if (binaryString === undefined || binaryString === null) {
        return 'Invalid input';
      }
    const binaryValues = binaryString.split(',').map(Number);

    const daysOfWeek = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
    let selectedDays = [];
  
    for (let i = 0; i < binaryValues.length; i++) {
      if (binaryValues[i] === 1) {
        selectedDays.push(daysOfWeek[i]);
      }
    }
  
    return selectedDays.join(', ');
  };

  export default convertBinaryToDayAbbreviation;