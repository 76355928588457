import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';
import ExportToExcel from '../../components/ExcelFile';
import PdfFile from '../../components/PdfFile';

const InfraDivisionMaster = () => {

  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    // Fetch data from the API
    axios.get(`${process.env.REACT_APP_INFRA_SERVICE_ADDRESS}/divisionlist/ALL`)
      .then(response => {
        // Set the fetched data to the state
        setRowData(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const gridOptions = {
    getRowHeight: () => 20, // Set a fixed row height of 20 pixels
    // Other grid options...
  };

  const columnDefs = [
    { headerName: 'Zone Code', field: 'zoneCode' },
    { headerName: 'Division Code', field: 'dvsnCode' },
    { headerName: 'Division Name', field: 'dvsnName' },    
  ];

  const columnOrder = ['zoneCode', 'dvsnCode','dvsnName'];

  const reorderedData = rowData.map(item => {
    const reorderedItem = {};
    columnOrder.forEach(key => {
      if (item.hasOwnProperty(key)) {
        reorderedItem[key] = item[key];
      }
    });
    return reorderedItem;
  });

  return (
    <div className="ag-theme-alpine" style={{ height: '650px'}}>

      <div>
        <table>
          <tr>
            <td> <PdfFile pdfData={rowData} columnDefinition={columnDefs} columnOrder={columnOrder} fileName="ZoneMaster" /> </td>
            <td> <ExportToExcel excelData={reorderedData} fileName="ZoneMaster" /> </td>
          </tr>
        </table>
      </div>

      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        //domLayout='autoHeight'
        autoSizeColumns={true}
        rowSelection="multiple"
        gridOptions={gridOptions}
      />
    </div>
  );

};

export default InfraDivisionMaster;