import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';

const SelectZone = ({ onSelectChange }) => {
  const [selectedZone, setSelectedZone] = useState(null);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    // Fetch suggestions from your API
    const fetchSuggestions = async () => {
      try {        
        const response = await axios.get(`${process.env.REACT_APP_INFRA_SERVICE_ADDRESS}/zonelist`);
        // Assuming your API response has a data property containing an array of options        
        const modifiedOptions = response.data.map(item => ({
            label: `${item.zoneCode} - ${item.zoneName}`, // Combine the two values
            value: `${item.zoneCode}`, // Use a unique identifier as the value
          }));
          setOptions(modifiedOptions);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    };

    fetchSuggestions();
  }, []);

  const handleChange = (selectedZone) => {
    setSelectedZone(selectedZone);

    onSelectChange(selectedZone);
  };

  return (
    <div>      
      <Select
        value={selectedZone}
        onChange={handleChange}
        options={options}
        isSearchable
        placeholder="Select or type to search..."
      />      
    </div>
  );
};
export default SelectZone;