
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';
import timeInDayHHMMSS from '../../functions/TimeInDayHHMMSS';
import binaryToDayAbbreviation from '../../functions/ConvertBinaryToDayAbbreviation';

const TrainsBetweenStations = () => {
  const [rowData, setRowData] = useState([]);
  const [departureStation, setDepartureStation] = useState(null);
  const [arrivalStation, setArrivalStation] = useState(null);
  const [stationOptions, setStationOptions] = useState([]);

  const [lowerGridRowData, setLowerGridRowData] = useState([]);

  useEffect(() => {
    // Fetch data from the API for station options
    axios.get(`${process.env.REACT_APP_INFRA_SERVICE_ADDRESS}/stationlist/NR/DLI`)
      .then(response => {
        // Process the response data to match the format required by Select component
        const options = response.data.map(station => ({
          value: station.sttnCode,
          label: `${station.sttnName} (${station.sttnCode})`
        }));
        // Set the fetched data to the state for station options
        setStationOptions(options);
      })
      .catch(error => {
        console.error('Error fetching station data:', error);
      });
  }, []);

  const columnDefs = [
    { headerName: 'Number', field: 'trainNumber'},
    { headerName: 'Name', field: 'trainName'},
    { headerName: 'Type', field: 'trainType'},
    { headerName: 'From', field: 'trainOrigSttn'},        
    { headerName: 'To', field: 'trainDstnSttn'},
    { headerName: 'Departure \n HH:MM:SS-DD', field: 'departureTime',headerClass: 'multiline-header', valueFormatter: timeInDayHHMMSS},
    { headerName: 'Arrival \n HH:MM:SS-DD', field: 'arrivalTime',headerClass: 'multiline-header', valueFormatter: timeInDayHHMMSS},        
    { headerName: 'Distance', field: 'trainDistance'},
    { headerName: 'Travel Time', field: 'trainTravelTime'},
    { headerName: 'Average Speed', field: 'trainSpeed'},
    { headerName: 'Days of Service', field: 'trainDayService', valueFormatter:binaryToDayAbbreviation},
    { headerName: 'Composition', field: 'coachComposition'},
    { headerName: 'No of Coaches', field: 'coachCount'},

     { headerName: 'TrainId', field: 'trainId'},
];

  const handleDepartureChange = (selectedOption) => {
    setDepartureStation(selectedOption);
  };

  const handleArrivalChange = (selectedOption) => {
    setArrivalStation(selectedOption);
  };

  const handleSubmit = () => {
    if (departureStation && arrivalStation) {
      // Fetch data from the API based on selected stations
   // axios.get(`${process.env.REACT_APP_REPORT_SERVICE_ADDRESS}/clusterTrain/${departureStation.value}/${arrivalStation.value}`)
      axios.get(`${process.env.REACT_APP_REPORT_SERVICE_ADDRESS}/clusterTrain/${departureStation.value}/${arrivalStation.value}`)
    //  axios.get(`${process.env.REACT_APP_REPORT_SERVICE_ADDRESS}/clusterTrain/NDLS/LKO`)
        .then(response => {
          // Set the fetched data to the state for grid data
          setRowData(response.data);
        })
        .catch(error => {
          console.error('Error fetching grid data:', error);
        });
    } else {
      alert('Please select both departure and arrival stations.');
    }
  };

  const handleRowDataInteraction = (rowData) => {


    if (departureStation && arrivalStation) {
      // Fetch data from the API based on selected stations
    //  axios.get(`http://localhost:8080/clusterTrain/${departureStation.value}/${arrivalStation.value}`)
     // axios.get(`${process.env.REACT_APP_REPORT_SERVICE_ADDRESS}/trainSchedule/${rowData.trainId}`)
     const url = `${process.env.REACT_APP_REPORT_SERVICE_ADDRESS}/trainSchedule/${rowData.trainId}`;

     // const url = `http://localhost:8080/trainSchedule/${rowData.trainId}`;
      axios.get(url)
        .then(response => {
          // Set the fetched data to the state for grid data
          setLowerGridRowData(response.data);
        })
        .catch(error => {
          console.error('Error fetching grid data:', error);
        });
    } else {
      alert('Please select both departure and arrival stations.');
    }
  };


  const selectStyles = {
    control: (provided) => ({
      ...provided,
      width: '150px', // Adjust the width as needed
      minHeight: '32px', // Set the height to match a button
    }),
  };

  const columnDefs15 = lowerGridRowData.length > 0 ? 
  Object.keys(lowerGridRowData[0]).map((key) => ({
    headerName: key.toUpperCase(), // Use key as column header
    field: key, // Use key to map to corresponding data field
  })) : [];


  return (
    <div>
      <div>
        <h3>Select Departure Station</h3>
        <Select
          value={departureStation}
          onChange={handleDepartureChange}
          options={stationOptions}
          styles={selectStyles}
        />
      </div>

      <div>
        <h3>Select Arrival Station</h3>
        <Select
          value={arrivalStation}
          onChange={handleArrivalChange}
          options={stationOptions}
          styles={selectStyles}
        />
      </div>

      <br />
      <button onClick={handleSubmit}>Submit</button>

      <div className="ag-theme-alpine" style={{ height: '400px', width: '100%' }}>
        <AgGridReact
          columnDefs={columnDefs}
          rowData={rowData}
          autoSizeColumns={true}
          rowSelection="multiple"
          onRowClicked={(event) => handleRowDataInteraction(event.data)}
        />

          <AgGridReact
          columnDefs={columnDefs15} // Use different column definitions if needed
          rowData={lowerGridRowData} // Use separate data for the lower grid
          autoSizeColumns={true}
          rowSelection="multiple"
        />
      </div>
    </div>
  );
};

export default TrainsBetweenStations;











