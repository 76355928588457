import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';
import ExportToExcel from '../../components/ExcelFile';
import PdfFile from '../../components/PdfFile';

const MapNodes = () => {

    const [rowData, setRowData] = useState([]);

    useEffect(() => {
        // Fetch data from the API
        axios.get(`${process.env.REACT_APP_INFRA_SERVICE_ADDRESS}/junctionNode`)
          .then(response => {
            // Set the fetched data to the state
            setRowData(response.data);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
      }, []);
  
    // const columnDefs = rowData.length > 0 ? 
    // Object.keys(rowData[0]).map((key) => ({
    //   headerName: key.toUpperCase(), // Use key as column header
    //   field: key, // Use key to map to corresponding data field
    // })) : [];
    const columnDefs = [
    { headerName: 'Zone', field: 'zoneCode', filter:true},
    { headerName: 'Division', field: 'dvsnCode',filter:true },
    { headerName: 'Station Code', field: 'sttnCode' },
    { headerName: 'Station Name', field: 'sttnName' },
    { headerName: 'Class', field: 'sttnClass' },
    { headerName: 'State', field: 'sttnState' },    
    { headerName: 'Zone I/C', field: 'zoneInterChange' },
    { headerName: 'Division I/C', field: 'dvsnInterChange' },
    { headerName: 'Lattitude', field: 'sttnLatitude' },
    { headerName: 'Longitude', field: 'sttnLongitude' },
    { headerName: 'X Coordinate', field: 'xcordinate' },
    { headerName: 'Y Coordinate', field: 'ycordinate' },
    ];
  
    const columnOrder = ['zoneCode','dvsnCode','sttnCode', 'sttnName','sttnClass', 'sttnState', 'zoneInterChange', 'dvsnInterChange', 'sttnLatitude', 'sttnLongitude',
                         'xcordinate', 'ycordinate'];
  
    const reorderedData = rowData.map(item => {
      const reorderedItem = {};
      columnOrder.forEach(key => {
        if (item.hasOwnProperty(key)) {
          reorderedItem[key] = item[key];
        }
      });
      return reorderedItem;
    });

  return (
    <div className="ag-theme-alpine" style={{ height: '650px'}}>
        <div>
        <table>
          <tr>
            <td> <PdfFile pdfData={rowData} columnDefinition={columnDefs} columnOrder={columnOrder} fileName="JunctionNode" /></td>

            <td> <ExportToExcel excelData={reorderedData} fileName="JunctionNode" /> </td>
          </tr>
        </table>
      </div>

      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        //domLayout='autoHeight'
        autoSizeColumns={true}
        rowSelection="multiple"
      />      
    </div>
  );

};

export default MapNodes;