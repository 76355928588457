import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/SideBar.css';


const SideBar = () => {
    return (
      // <div className={`sidebar ${isOpen ? 'open' : ''}`}>
        <div className="sidebar-content"> 
          {/* <li><Link to="/">Home</Link></li>
          <hr></hr> */}
          <li>
            <Link to="/InfraZoneMaster">Zone Master</Link>
          </li> 
          <li>
            <Link to="/InfraDivisionMaster">Division Master</Link>
          </li> 
          <li>
            <Link to="/InfraStationMaster">Station Master</Link>
          </li> 
          <li>
            <Link to="/StationLine">Station Line Master</Link>
          </li>
          <li>
            <Link to="/InfraBlockSectionMaster">Block Section Master</Link>
          </li> 
          <li>
            <Link to="/infraBlockSectionLineMaster">Block Section Line Master</Link>
          </li>
          <li>
            <Link to="/InfraSectionMaster">Section Master</Link>
          </li> 
          <li>
            <Link to="/InfraSectionStations">Section Stations</Link>
          </li> 
          <li>
            <Link to="/InfraBoardMaster">Board Master</Link>
          </li>
          <li>
            <Link to="/InfraBoardStation">Board Station</Link>
          </li>
          <li>
            <Link to="/StationPlatfomDetail">Station Platform</Link>
          </li>
          <li>
            <Link to="/BlockCorridor">BlockCorridor</Link>
          </li> 
          {/* <li>
            <Link to="/BlockSectionPsr">BlockSectionPSR</Link>
          </li> */}
          {/* <li>
            <Link to="/BlockCSectionCurvature">Block Section Curvature</Link>
          </li>  */}
          {/* <li>
            <Link to="/BlockSectionGradient">Block Section Gradient</Link>
          </li> */}          

         <hr></hr>
         
         {/* <li>
            <Link to="/DataEntryStatus">Data Entry Status</Link>
          </li>  */}
          {/* <li>
            <Link to="/StationChangePoint">Station Change Point</Link>
          </li> */}
          <li>
            <Link to="/StationTimeTable">Station Time Table</Link>
          </li>          
         <li>
            <Link to="/GaugeDiscrepency">Gauge Discrepency</Link>
          </li> 
          <li>
            <Link to="/TractionDiscrepency">Traction Discrepency</Link>
          </li> 
          <li>
            <Link to="/JunctionDiscrepency">Junction Discrepency</Link>
          </li> 
          <li>
            <Link to="/ZoneTractionSummary">Zone Traction Summary</Link>
          </li> 
          <li>
            <Link to="/DivisionTractionSummary">Division Traction Summary</Link>
          </li>
          {/* <li>
            <Link to="/TrafficDensity">Traffic Density</Link>
          </li> */}

          <hr></hr>

          <li>
            <Link to="/TrainServiceSummary">Train Service Summary</Link>
          </li> 
          {/* <li>
            <Link to="/TrainsBetweenStations">Trains Between Stations</Link>
          </li>  */}          
          {/* <li>
            <Link to="/ProposalFilter">Proposal Filter</Link>
          </li>  */}
           
          
          {/* <li>
            <Link to="/PttTimingsByZoneDivision">PTT Timings</Link>
          </li> */}
          {/* <li>
            <Link to="/ICTimingsByZoneDivision">IC Timings</Link>
          </li> */}
          {/* <li>
            <Link to="/PassingTrainTimingsByZoneDivision">Passing Train Timings</Link>
          </li> */}
          {/* <li>
            <Link to="/TrainTractionSummary">Train Traction Summary</Link>
          </li> */}
          {/* <li>
            <Link to="/ZonewiseExtraTimeConsumed">Zonewise Extra Time</Link>
          </li> */}
          {/* <li>
            <Link to="/DivisionwiseExtraTimeConsumed">Divisionwise Extra Time</Link>
          </li> */}
          {/* <li>
            <Link to="/TrainwiseExtraTimeConsumed">Trainwise Extra Time</Link>
          </li> */}
          {/* <li>
            <Link to="/TrainWiseWttStoppages">Trainwise Wtt Stoppage</Link>
          </li> */}
          {/* <li>
            <Link to="/TrainWisePttStoppages">Trainwise Ptt Stoppage</Link>
          </li> */}
          {/* <li>
            <Link to="/StationwisePttStoppages">Stationwise Ptt Stoppage</Link>
          </li> */}
          {/* <li>
            <Link to="/StationwiseWttStoppages">Stationwise Ptt Stoppage</Link>
          </li> */}
          <li>
            <Link to="/LongStoppageZoneSummary">Long Stoppage Zone Summary</Link>
          </li>
          <li>
            <Link to="/LongStoppageDivisionSummary">Long Stoppage Division Summary</Link>
          </li>
          <li>
            <Link to="/LongStoppageStationwiseDetail">Long Stoppage Stationwise Detail</Link>
          </li>
          {/* <li>
            <Link to="/TrainHistoryComparision">Train History Comparision</Link>
          </li> */}
          {/* <li>
            <Link to="/TrainComparisionZonewise">Train Comparision Zonewise</Link>
          </li> */}
          <li>
            <Link to="/TrainSpeedBreakup">Train Speed Breakup</Link>
          </li>
          <li>
            <Link to="/NonStopLongRuntimeTrains">Non Stop Long Runtime Trains</Link>
          </li>
          <li>
            <Link to="/ZonalSummaryComparision">Zonal Summary Comparision</Link>
          </li>
          <li>
            <Link to="/DivisionalSummaryComparision">Divisional Summary Comparision</Link>
          </li>

          <hr></hr>
          
          <li>
            <Link to="/MapNodes">Nodes</Link>
          </li> 
          {/* <li>
            <Link to="/MapLinks">Links</Link>
          </li>  */}
          {/* <li>
            <Link to="/MapInternalNodes">Internal Nodes</Link>
          </li>  */}
          {/* <li>
            <Link to="/MapRouteExceptions">Route Exceptions</Link>
          </li>  */}
          {/* <li>
            <Link to="/MapZonalNodes">Zonal Nodes</Link>
          </li>  */}

        <hr></hr>

          <li>
          <Link to="/TimeTableSummary">TimeTableSummary</Link>
          </li>
          <li>
          <Link to="/TimeTableZonalSummary">TimeTableZonalSummary</Link>
          </li>
          <li>
          <Link to="/TimeTableDivisionalSummary">TimeTableDivisionalSummary</Link>
          </li>

<hr></hr>

{/* <button onClick={toggleSidebar}>Toggle Sidebar</button> */}
        </div>
        
      // </div>
    );
  };

  export default SideBar;