import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';


const MapInternalNodes = () => {

    const [rowData, setRowData] = useState([]);

    useEffect(() => {
        // Fetch data from the API
        axios.get(`${process.env.REACT_APP_INFRA_SERVICE_ADDRESS}/internalNode`)
          .then(response => {
            // Set the fetched data to the state
            setRowData(response.data);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
      }, []);
  
   

    const columnDefsMeraj = [
      { key: 'trainType' , name: 'Type' }, 
      { key: 'trainCount' , name: 'Count' },  
      { key: 'trainCountAftdCR' , name: 'CR Passing' }, 
      { key: 'trainCountCR' , name: 'CR Owned' },  
      { key: 'trainCountAftdECR' , name: 'ECR Passing' },  
      { key: 'trainCountECR' , name: 'ECR Owned' },  
      { key: 'trainCountAftdER' , name: 'ER Passing' },  
      { key: 'trainCountER' , name: 'ER Owned' },  
      { key: 'trainCountAftdKR' , name: 'KR Passing' },  
      { key: 'trainCountKR' , name: 'KR Owned' },  
      { key: 'trainCountAftdNCR' , name: 'NCR Passing' },  
      { key: 'trainCountNCR' , name: 'NCR Owned' },  
      { key: 'trainCountAftdNER' , name: 'NER Passing' },  
      { key: 'trainCountNER' , name: 'NER Owned' },  
      { key: 'trainCountAftdNFR' , name: 'NFR Passing' },  
      { key: 'trainCountNFR' , name: 'NFR Owned' },  
      { key: 'trainCountAftdNR' , name: 'NR Passing' },  
      { key: 'trainCountNR' , name: 'NR Owned' },  
      { key: 'trainCountAftdNWR' , name: 'NWR Passing' },  
      { key: 'trainCountNWR' , name: 'NWR Owned' },  
      { key: 'trainCountAftdSCR' , name: 'SCR Passing' },  
      { key: 'trainCountSCR' , name: 'SCR Owned' },  
      { key: 'trainCountAftdSECR' , name: 'SECR Passing' },  
      { key: 'trainCountSECR' , name: 'SECR Owned' },  
      { key: 'trainCountAftdSER', name: 'SER Passing' }, 
      { key: 'trainCountSER' , name: 'SER Owned' },  
      { key: 'trainCountAftdSR' , name: 'SR Passing' },  
      { key: 'trainCountSR' , name: 'SR Owned' },  
      { key: 'trainCountAftdSWR' , name: 'SWR Passing' },  
      { key: 'trainCountSWR' , name: 'SWR Owned' },  
      { key: 'trainCountAftdWCR' , name: 'WCR Passing' },  
      { key: 'trainCountWCR' , name: 'WCR Owned' },  
      { key: 'trainCountAftdWR' , name: 'WR Passing' },  
      { key: 'trainCountWR' , name: 'WR Owned' },  
      { key: 'trainCountAftd' , name: 'Passing Total' }, 
      // Add more columns based on your data structure
    ];

    const columnDefs11 = [
      { headerName: 'Train Type', field: 'trainType' },
      { headerName: 'Train Count', field: 'trainCount' },
      { headerName: 'Train Count CR', field: 'trainCountCR' },
      { headerName: 'Train Count WR', field: 'trainCountWR' },
      { headerName: 'Train Count WCR', field: 'trainCountWCR' },
      { headerName: 'Train Count SR', field: 'trainCountSR' },
      { headerName: 'Train Count SCR', field: 'trainCountSCR' },
      { headerName: 'Train Count SWR', field: 'trainCountSWR' },
      { headerName: 'Train Count NCR', field: 'trainCountNCR' },
      { headerName: 'Train Count NR', field: 'trainCountNR' },
      { headerName: 'Train Count NWR', field: 'trainCountNWR' },
      { headerName: 'Train Count NER', field: 'trainCountNER' },
      { headerName: 'Train Count KR', field: 'trainCountKR' },
      { headerName: 'Train Count ECR', field: 'trainCountECR' },
      { headerName: 'Train Count ECOR', field: 'trainCountECOR' },
      { headerName: 'Train Count ER', field: 'trainCountER' },
      { headerName: 'Train Count NFR', field: 'trainCountNFR' },
      { headerName: 'Train Count SECR', field: 'trainCountSECR' },
      { headerName: 'Train Count SER', field: 'trainCountSER' },
      { headerName: 'Train Count Aftd', field: 'trainCountAftd' },
      { headerName: 'Train Count Aftd CR', field: 'trainCountAftdCR' },
      { headerName: 'Train Count Aftd WR', field: 'trainCountAftdWR' },
      { headerName: 'Train Count Aftd WCR', field: 'trainCountAftdWCR' },
      { headerName: 'Train Count Aftd SR', field: 'trainCountAftdSR' },
      { headerName: 'Train Count Aftd SCR', field: 'trainCountAftdSCR' },
      { headerName: 'Train Count Aftd SWR', field: 'trainCountAftdSWR' },
      { headerName: 'Train Count Aftd NCR', field: 'trainCountAftdNCR' },
      { headerName: 'Train Count Aftd NR', field: 'trainCountAftdNR' },
      { headerName: 'Train Count Aftd NWR', field: 'trainCountAftdNWR' },
      { headerName: 'Train Count Aftd NER', field: 'trainCountAftdNER' },
      { headerName: 'Train Count Aftd KR', field: 'trainCountAftdKR' },
      { headerName: 'Train Count Aftd ECR', field: 'trainCountAftdECR' },
      { headerName: 'Train Count Aftd ECOR', field: 'trainCountAftdECOR' },
      { headerName: 'Train Count Aftd ER', field: 'trainCountAftdER' },
      { headerName: 'Train Count Aftd NFR', field: 'trainCountAftdNFR' },
      { headerName: 'Train Count Aftd SECR', field: 'trainCountAftdSECR' },
      { headerName: 'Train Count Aftd SER', field: 'trainCountAftdSER' },
    ];

    const columnDefs22 = [
        { headerName: 'Division Code', field: 'dvsnCode' },
        { headerName: 'Division Name', field: 'dvsnName' },
        { headerName: 'Zone Code', field: 'zoneCode' },
      ];
    const columnDefs121 = [
      { headerName: 'Zone Code', field: 'zoneCode' },
      { headerName: 'Division Code', field: 'dvsnCode' },
      { headerName: 'Verify Status', field: 'verifyStatus' },
      { headerName: 'Verify Date', field: 'verifyDate' },
      { headerName: 'Mobile Number (Zone)', field: 'mobileNumberZone' },
      { headerName: 'Mobile Number (Division)', field: 'mobileNumberDvsn' },
      { headerName: 'Mobile Number (Zone Cptm)', field: 'mobileNumberZoneCptm' },
      { headerName: 'Mobile Number (Zone Srdom)', field: 'mobileNumberZoneSrdom' },
      { headerName: 'Verify Status Sttn', field: 'verifyStatusSttn' },
      { headerName: 'Verify Status Blcksctn', field: 'verifyStatusBlcksctn' },
      { headerName: 'Verify Status Section', field: 'verifyStatusSection' },
      { headerName: 'Verify Status Occupancy', field: 'verifyStatusOccupancy' },
      { headerName: 'Verify Status Corridor', field: 'verifyStatusCorridor' },
      { headerName: 'Verify Status Loco Change', field: 'verifyStatusLocoChange' },
      { headerName: 'Verify Status Crew Change', field: 'verifyStatusCrewChange' },
      { headerName: 'Verify Status Revarsal', field: 'verifyStatusRevarsal' },
      { headerName: 'Verify Status Psr', field: 'verifyStatusPsr' },
      { headerName: 'Verify Status Gradient', field: 'verifyStatusGradient' },
      { headerName: 'Verify Status Cureve', field: 'verifyStatusCureve' },
      { headerName: 'Verify Status Route Link', field: 'verifyStatusRouteLink' },
      { headerName: 'Verify Status Train Profile', field: 'verifyStatusTrainProfile' },
      { headerName: 'Verify Status Platform', field: 'verifyStatusPlatform' },
      // Add more column definitions as needed...
    ];
    
    const columnDefs = [
      { headerName: 'Station State', field: 'sttnState' },
      { headerName: 'Zone Code', field: 'zoneCode' },
      { headerName: 'Division Code', field: 'dvsnCode' },
      { headerName: 'Station Code', field: 'sttnCode' },
      { headerName: 'Station Name', field: 'sttnName' },
      { headerName: 'Station Class', field: 'sttnClass' },
      { headerName: 'Station Latitude', field: 'sttnLatitude' },
      { headerName: 'Station Longitude', field: 'sttnLongitude' },
      { headerName: 'Division Interchange', field: 'dvsnInterChange' },
      { headerName: 'Zone Interchange', field: 'zoneInterChange' },
      { headerName: 'Node1 Code', field: 'node1Code' },
      { headerName: 'Node2 Code', field: 'node2Code' },
      { headerName: 'Sequence from Node1', field: 'sequenceFromNode1' },
      { headerName: 'Sequence from Node2', field: 'sequenceFromNode2' },
      { headerName: 'Junction', field: 'junction' },
      { headerName: 'Distance from Node1', field: 'distanceFromNode1' },
      { headerName: 'Distance from Node2', field: 'distanceFromNode2' },
      { headerName: 'X Coordinate', field: 'xcordinate' },
      { headerName: 'Y Coordinate', field: 'ycordinate' }
    ];
    
  return (
    <div className="ag-theme-alpine" style={{ height: '1200px', width: '100%' }}>
        {/* <button onClick={togglePanel}>Toggle Panel</button> */}
      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        //domLayout='autoHeight'
        autoSizeColumns={true}
        rowSelection="multiple"
      />
      {/* {isPanelVisible && <PanelContent />} */}
    </div>
  );

};

export default MapInternalNodes;