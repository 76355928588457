import React, { useState } from 'react';
import TopBottomBar from './components/TopBottomBar'; 
import LoginPage from './authorization/LoginPage';

  
const App = () => {

  const [user, setUser] = useState(null);

  const handleLogin = (user) => {
    setUser(user);
  };

  return (
    <div>
      {user ? (
        <TopBottomBar />
      ) : (
        <LoginPage onLogin={handleLogin} />
      )}
    </div>
  );
};

export default App;

