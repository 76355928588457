import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';
import ExportToExcel from '../../components/ExcelFile';
import PdfFile from '../../components/PdfFile';

const TractionDiscrepencyList = () => {

  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    // Fetch data from the API
    axios.get(`${process.env.REACT_APP_INFRA_SERVICE_ADDRESS}/TractionDiscrepancylist`)
      .then(response => {
        // Set the fetched data to the state
        setRowData(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);


  const columnDefs = [
    { headerName: 'Zone', field: 'zoneCode', filter: true },
    { headerName: 'Division', field: 'dvsnCode', filter: true },
    { headerName: 'Station', field: 'sttnCode', filter: true },
    { headerName: 'Station Line Number', field: 'sttnLineNumber' },
    { headerName: 'Station Line Traction', field: 'sttnLineTraction' },
    { headerName: 'Block Section', field: 'blockSctn' },
    { headerName: 'Block Section Line Number', field: 'blockSctnLineNumber' },
    { headerName: 'Block Section Line Traction', field: 'blockSctnTraction' },
  ];

  const columnOrder = ['zoneCode', 'dvsnCode', 'sttnCode', 'sttnLineNumber', 'sttnLineTraction', 'blockSctn', 'blockSctnLineNumber', 'blockSctnTraction'];

  const reorderedData = rowData.map(item => {
    const reorderedItem = {};
    columnOrder.forEach(key => {
      if (item.hasOwnProperty(key)) {
        reorderedItem[key] = item[key];
      }
    });
    return reorderedItem;
  });


  return (
    <div className="ag-theme-alpine" style={{ height: '650px'}}>

      <div>
        <table>
          <tr>
            <td> <PdfFile pdfData={rowData} columnDefinition={columnDefs} columnOrder={columnOrder} fileName="TractionDiscrepancyList" /></td>

            <td> <ExportToExcel excelData={reorderedData} fileName="TractionDiscrepancyList" /> </td>
          </tr>
        </table>
      </div>

      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        //domLayout='autoHeight'
        autoSizeColumns={true}
        rowSelection="multiple"
      />
    </div>
  );

};

export default TractionDiscrepencyList;