import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';
import SelectDivision from '../../components/SelectDivision';
import SelectZone from '../../components/SelectZone';
import ExportToExcel from '../../components/ExcelFile';
import PdfFile from '../../components/PdfFile';

const InfraBlockSectionMaster = () => {

    const [rowData, setRowData] = useState([]);
    const [selectedZone, setSelectedZone] = useState(null);
    const [selectedDivision, setSelectedDivision] = useState(null);

    const handleSelectZone = (selectedZone) => {
      console.log("Selected Zone:", selectedZone.value);

      setSelectedZone(selectedZone.value);
    };

    const handleSelectDivision = (selectedDivision) => {
      console.log("Selected Division:", selectedDivision.value);
      setSelectedDivision(selectedDivision.value);
    };    

    const handleButtonClick = () => {

      if (selectedZone && selectedDivision) {

        axios.get(`${process.env.REACT_APP_INFRA_SERVICE_ADDRESS}/blocksectionlist/${selectedZone}/${selectedDivision}`)
          .then(response => {
            // Set the fetched data to the state
            setRowData(response.data);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
      }      
    };
  
    const columnDefs = [      
      { headerName: 'Zone Code', field: 'zoneCode' },
      { headerName: 'Division Code', field: 'dvsnCode' },
      { headerName: 'Block Section', field: 'blockSctn' },
      { headerName: 'Inter-Distance', field: 'interDistance' },
      { headerName: 'Track Count', field: 'trackCount' }
    ];
    
    const columnOrder = ['zoneCode','dvsnCode','blockSctn', 'interDistance','trackCount'];

  const reorderedData = rowData.map(item => {
    const reorderedItem = {};
    columnOrder.forEach(key => {
      if (item.hasOwnProperty(key)) {
        reorderedItem[key] = item[key];
      }
    });
    return reorderedItem;
  });

  return (
    <div className="ag-theme-alpine" style={{ height: '650px' }}>
        <div>
        <table>
          <tr>
            <td style={{ width: '210px' }}>
              <SelectZone onSelectChange={handleSelectZone} />
            </td>
            <td style={{ width: '210px' }}>
              {selectedZone && (
                <SelectDivision selectedValue={selectedZone} onSelectChange={handleSelectDivision} />
              )}
            </td>
            <td> <button onClick={handleButtonClick}>Fetch Data</button></td>
            <td> <PdfFile pdfData={rowData} columnDefinition={columnDefs} columnOrder={columnOrder} fileName="StationMaster" /> </td>
            <td> <ExportToExcel excelData={reorderedData} fileName="StationMaster" /> </td>
          </tr>
        </table>
      </div>

      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        //domLayout='autoHeight'
        autoSizeColumns={true}
        rowSelection="multiple"
      />
      {/* {isPanelVisible && <PanelContent />} */}
    </div>
  );

};

export default InfraBlockSectionMaster;