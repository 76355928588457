import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';
import SelectDivision from '../../components/SelectDivision';
import SelectZone from '../../components/SelectZone';

const ICTimingsByZoneDivision = () => {
   
    const [rowData, setRowData] = useState([]);
    const [selectedZone, setSelectedZone] = useState(null);
    const [selectedDivision, setSelectedDivision] = useState(null);

  const handleSelectZone = (selectedZone) => {
    setSelectedZone(selectedZone.value);
  };

  const handleSelectDivision = (selectedDivision) => {
    setSelectedDivision(selectedDivision.value);
  };

  const handleButtonClick = () => {
    
    if (selectedZone && selectedDivision) {
      // Call the additional API using all three selected values
      axios
        .get(`${process.env.REACT_APP_REPORT_SERVICE_ADDRESS}/ictimingbyzonedvsn/${selectedZone}/${selectedDivision}`)
        .then((response) => {
          // Set the additional data to state
          setRowData(response.data);
        })
        .catch((error) => {
          console.error('Error fetching additional data:', error);
        });
    }
  };

      const columnDefs = [        
        { headerName: 'Train Number', field: 'trainNumber'},
        { headerName: 'TrainId', field: 'trainId'},
        { headerName: 'ProposalId', field: 'proposalId'},        
        { headerName: 'Owning Railway', field: 'owningZone'},
        { headerName: 'Train Type', field: 'trainType'},
        { headerName: 'Train SubType', field: 'trainSubType'},
        { headerName: 'Origin', field: 'origSttn'},
        { headerName: 'Destination', field: 'dstnSttn'},
        { headerName: 'Departure Time', field: 'departureTime'},
        { headerName: 'Arrival Time', field: 'arrivalTime'},
        { headerName: 'Days of Service', field: 'trainDayService'},
        { headerName: 'IC Timing', field: 'icTime'},
        { headerName: 'My IC Timing', field: 'myIcTime'},
    ];
        

  return (
    <div className="ag-theme-alpine" style={{ height: '600px', width: '100%' }}>
        <div>             
          <td style={{ width: '210px' }}>
              <SelectZone onSelectChange={handleSelectZone} />
          </td>
          <td style={{ width: '210px' }}>
              {selectedZone && (
              <SelectDivision selectedValue={selectedZone} onSelectChange={handleSelectDivision} />
              )}
          </td>
          <td>
              <button onClick={handleButtonClick}>Fetch Data</button>
          </td>
      </div>
      
      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        //domLayout='autoHeight'
        autoSizeColumns={true}
        rowSelection="multiple"
      />
    </div>
  );

};

export default ICTimingsByZoneDivision;