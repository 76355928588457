import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';
import SelectDivision from '../../components/SelectDivision';
import SelectZone from '../../components/SelectZone';
import SelectSection from '../../components/SelectSection';
import ExportToExcel from '../../components/ExcelFile';
import PdfFile from '../../components/PdfFile';

const InfraSectionStations = () => {
   
    const [rowData, setRowData] = useState([]);
    const [selectedZone, setSelectedZone] = useState(null);
    const [selectedDivision, setSelectedDivision] = useState(null);
    const [selectedSection, setSelectedSection] = useState(null);

    const handleSelectZone = (selectedZone) => {
      console.log("Selected Zone:", selectedZone.value);
  
      setSelectedZone(selectedZone.value);
    };
  
    const handleSelectDivision = (selectedDivision) => {
      console.log("Selected Division:", selectedDivision.value);
      setSelectedDivision(selectedDivision.value);
    };
  
    const handleSelectSection = (selectedSection) => {
      console.log("Selected Section:", selectedSection.value);
      setSelectedSection(selectedSection.value);
    };

    const handleButtonClick = () => {

      if (selectedSection) {
        // Fetch data from the API
        axios.get(`${process.env.REACT_APP_INFRA_SERVICE_ADDRESS}/SectionStation/${selectedSection}`)
          .then(response => {
            // Set the fetched data to the state
            setRowData(response.data);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
      } 
    };
    

    const columnDefs = [
        { headerName: 'Section Code', field: 'sctnCode',hide:true},
        { headerName: 'Seq', field: 'sttnSeq'},  
        { headerName: 'Station', field: 'sttnCode'},         
        { headerName: 'Class', field: 'sttnClass'},     
        { headerName: 'Block Section', field: 'blockSctn'}, 
        { headerName: 'Speed', field: 'blckSctnSpeed'}
    ];
        
    const columnOrder = ['sctnCode', 'sttnSeq', 'sttnCode', 'sttnClass', 'blockSctn', 'blckSctnSpeed'];

  const reorderedData = rowData.map(item => {
    const reorderedItem = {};
    columnOrder.forEach(key => {
      if (item.hasOwnProperty(key)) {
        reorderedItem[key] = item[key];
      }
    });
    return reorderedItem;
  });


  return (
    <div className="ag-theme-alpine" style={{ height: '600px', width: '1300px' }}>
        <div>
        <table>
          <tr>
            <td style={{ width: '210px' }}>
              <SelectZone onSelectChange={handleSelectZone} />
            </td>
            <td style={{ width: '210px' }}>
              {selectedZone && <SelectDivision selectedValue={selectedZone} onSelectChange={handleSelectDivision} />}
            </td>
            <td style={{ width: '210px' }}>
              {selectedZone && selectedDivision && <SelectSection selectedValue={selectedZone} selectedValue1={selectedDivision} onSelectChange={handleSelectSection} />}
            </td>
            <td> <button onClick={handleButtonClick}>Fetch Data</button> </td>

            <td> <PdfFile pdfData={rowData} columnDefinition={columnDefs} columnOrder={columnOrder} fileName="SectionStationList" /></td>

            <td> <ExportToExcel excelData={reorderedData} fileName="SectionStationList" /> </td>
          </tr>
        </table>
      </div>

      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        //domLayout='autoHeight'
        autoSizeColumns={true}
        rowSelection="multiple"
      />
    </div>
  );

};

export default InfraSectionStations;