import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';

const AutocompleteComponent = ({  selectedValue, selectedValue1, onSelectChange  }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);

  useEffect(() => {    
    // Fetch suggestions from your API
    const fetchSuggestions = async () => {
      try {              
        const response = await axios.get(`${process.env.REACT_APP_INFRA_SERVICE_ADDRESS}/blocksectionlist/${selectedValue}/${selectedValue1}`);
        // Assuming your API response has a data property containing an array of options
        
        const modifiedOptions = response.data.map(item => ({
            label: `${item.blockSctn}`, // Combine the two values
            value: `${item.blockSctn}`, // Use a unique identifier as the value
          }));
          setOptions(modifiedOptions);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    };

    fetchSuggestions();
  }, [selectedValue, selectedValue1]);


  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);

    onSelectChange(selectedOption);
  };

  return (
    <div>
      
      <Select
        value={selectedOption}
        onChange={handleChange}
        options={options}
        isSearchable
        placeholder="Select or type to search..."
      />      
    </div>
  );
};
export default AutocompleteComponent;