import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';

const TrafficDensity = () => {
   
    const [rowData, setRowData] = useState([]);

    useEffect(() => {
        // Fetch data from the API
        axios.get(`${process.env.REACT_APP_REPORT_SERVICE_ADDRESS}/TrafficDensity/`)
          .then(response => {
            // Set the fetched data to the state
            setRowData(response.data);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
      }, []);
    

    const columnDefs = [         
        { headerName: 'Zone', field: 'zoneCode'},      
        { headerName: 'Division', field: 'dvsnCode'},
        { headerName: 'Block Section', field: 'blockSctn'},
        { headerName: 'Block Section Line', field: 'blockSctnLines'},
        { headerName: 'From Station', field: 'fromSttnCode'},
        { headerName: 'To Station', field: 'toSttnCode'},
        { headerName: 'Signal Type', field: 'signal'},
        { headerName: 'Traction Type', field: 'traction'},
        { headerName: 'Speed', field: 'speed'},
        { headerName: 'From Longitude', field: 'fromLongitude'},
        { headerName: 'From Latitude', field: 'fromLatitude'},
        { headerName: 'From X Co-ordinate', field: 'fromXCoordinate'},
        { headerName: 'From Y Co-ordinate', field: 'fromYVCordinate'},
        { headerName: 'To Longitude', field: 'toLongitude'},
        { headerName: 'To Latitude', field: 'toLatitude'},
        { headerName: 'To X Co-ordinate', field: 'toXCoordinate'},
        { headerName: 'To Y Co-ordinate', field: 'toYCoordinate'},
        { headerName: 'No of Trains', field: 'noOfTrain'},
        { headerName: 'Schedule Seq No', field: 'scheduleSeqNo'},
    ];
        

  return (
    <div className="ag-theme-alpine" style={{ height: '600px', width: '100%' }}>
      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        //domLayout='autoHeight'
        autoSizeColumns={true}
        rowSelection="multiple"
      />
    </div>
  );

};

export default TrafficDensity;