import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Select from 'react-select';
import Home from '../pages/Home';
import axios from 'axios';
import '../App.css';
import '../styles/TopBottomBar.css'
import SideBar from './SideBar';

import TrainServiceSummary from '../pages/TrainService/TrainServiceSummary';
import TrainsBetweenStations from '../pages/TrainService/TrainsBetweenStations';
import DataEntryStatus from '../pages/DataStatus/DataEntryStatus';

import GaugeDiscrepency from '../pages/DataStatus/GaugeDiscrepencyList';
import TractionDiscrepency from '../pages/DataStatus/TractionDiscrepencyList';
import JunctionDiscrepency from '../pages/DataStatus/JunctionDiscrepencyList';
import PttTimingsByZoneDivision from '../pages/TrainService/PttTimingsByZoneDivision';
import ICTimingsByZoneDivision from '../pages/TrainService/ICTimingsByZoneDivision';
import PassingTrainTimingsByZoneDivision from '../pages/TrainService/PassingTrainTimingsByZoneDivision';

import InfraZoneMaster from '../pages/InfraNetwork/InfraZoneMaster';
import InfraDivisionMaster from '../pages/InfraNetwork/InfraDivisionMaster';
import InfraStationMaster from '../pages/InfraNetwork/InfraStationMaster';
import InfraBlockSectionMaster from '../pages/InfraNetwork/InfraBlockSectionMaster';
import InfraBlockSectionLineMaster from '../pages/InfraNetwork/BlockSectionLine';
import InfraSectionMaster from '../pages/InfraNetwork/InfraSectionMaster';
import InfraSectionStations from '../pages/InfraNetwork/InfraSectionStations';
import InfraBoardMaster from '../pages/InfraNetwork/BoardList';
import InfraBoardStation from '../pages/InfraNetwork/BoardStation';
import MapNodes from '../pages/RouteNetwork/MapNodes';
import MapLinks from '../pages/RouteNetwork/MapLinks';
import MapInternalNodes from '../pages/RouteNetwork/MapInternalNodes';
import MapRouteExceptions from '../pages/RouteNetwork/MapRouteExceptions';
import MapZonalNodes from '../pages/RouteNetwork/MapZonalNodes';
import BlockSectionPsr from '../pages/Topography/BlockSectionPsr';
import BlockCorridor from '../pages/Topography/BlockCorridor';
import BlockSectionGradient from '../pages/Topography/BlockSectionGradient';
import BlockSectionCurvature from '../pages/Topography/BlockSectionCurvature';
import StationLine from '../pages/InfraNetwork/StationLine';
import StationChangePoint from '../pages/InfraNetwork/StationChangePoint';
import StationPlatfomDetail from '../pages/InfraNetwork/StationPlatfomDetail';
import ZoneTractionSummary from '../pages/DataStatus/ZoneTractionSummary';
import DivisionTractionSummary from '../pages/DataStatus/DivisionTractionSummary';
import TrainTractionSummary from '../pages/TrainService/TrainTractionSummary';
import ZonewiseExtraTimeConsumed from '../pages/TrainService/ZonewiseExtraTimeConsumed';
import DivisionwiseExtraTimeConsumed from '../pages/TrainService/DivisionwiseExtraTimeConsumed';
import TrainwiseExtraTimeConsumed from '../pages/TrainService/TrainwiseExtraTimeConsumed';
import TrainWiseWttStoppages from '../pages/TrainService/TrainWiseWttStoppages';
import TrainWisePttStoppages from '../pages/TrainService/TrainWisePttStoppages';
import StationwisePttStoppages from '../pages/TrainService/StationwisePttStoppages';
import StationwiseWttStoppages from '../pages/TrainService/StationwiseWttStoppages';
import TrainHistoryComparision from '../pages/TrainService/TrainHistoryComparision';
import TrainComparisionZonewise from '../pages/TrainService/TrainComparisionZonewise';
import TrafficDensity from '../pages/InfraNetwork/TrafficDensity';
import TrainSpeedBreakup from '../pages/TrainService/TrainSpeedBreakup';
import LongStoppageZoneSummary from '../pages/TrainService/LongStoppageZoneSummary';
import LongStoppageDivisionSummary from '../pages/TrainService/LongStoppageDivisionSummary';
import LongStoppageStationwiseDetail from '../pages/TrainService/LongStoppageStationwiseDetail';
import NonStopLongRuntimeTrains from '../pages/TrainService/NonStopLongRuntimeTrains';
import ZonalSummaryComparision from '../pages/TrainService/ZonalSummaryComparision';
import DivisionalSummaryComparision from '../pages/TrainService/DivisionalSummaryComparision';
import ProposalFilter from '../pages/TrainService/ProposalFilter';
import StationChnagePoint from '../pages/DataStatus/StationChnagePoint';
import StationTimeTable from '../pages/DataStatus/StationTimeTable';
import TimeTableSummary from '../pages/TimeTable/Summary';
import TimeTableZonalSummary from '../pages/TimeTable/ZonalSummary';
import TimeTableDivisionalSummary from '../pages/TimeTable/DivisionalSummary';

const TopBottomBar = () => {
  
  const [showMyComp, setShowMyComp] = useState(false);
  const [stationOptions, setStationOptions] = useState([]);
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handleButtonClick = () => {
    setShowMyComp(true);
  };

  useEffect(() => {
    // Fetch data from the API for station options
    axios.get(`${process.env.REACT_APP_REPORT_SERVICE_ADDRESS}/WebReportConfig`)
      .then(response => {
        // Process the response data to match the format required by Select component
        const options = response.data.map(station => ({
          value: station.reportLink,
          label: `${station.reportNumber} - ${station.reportName}`
        }));
        // Set the fetched data to the state for station options
        setStationOptions(options);
      })
      .catch(error => {
        console.error('Error fetching station data:', error);
      });
  }, []);

  const headerStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    color: 'blue'
  };

  const footerStyle = {
    fontSize: '16px',
    fontWeight: 'bold',
    color: 'white'
  };

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      width: '262px', // Adjust the width as needed
      minHeight: '32px', // Set the height to match a button
      fontSize: '16px',
      FontFace: 'Tahoma',
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: '16px', // Set your desired font size here
      FontFace: 'Times New Roman',
    }),
  };
  
  const handleChange = (selectedOption) => {
    // Assuming your route paths are /page1, /page2, etc.
    alert(selectedOption.value);
    //history.push(`/${selectedOption.value}`);
  };

  // const handleSidebarLinkClick = () => {
  //   // Scroll to the top of the main-content div
  //   document.querySelector('.main-content').scrollTop = 0;
  // };

  return (
    <div className="top-bottom-bars-container">
      <div className="top-bar" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>  

         <div style={{display: 'flex',alignSelf:'flex-start'}}>
          <button onClick={toggleSidebar}>Toggle Sidebar</button>
         </div>

         <header style={headerStyle}>SATSaNG MIS Report</header>
        
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}> <button onClick={handleButtonClick}> Network Map</button></div>

      </div>
      <div className="main-content" style={{ height: 'calc(100vh - 80px)', overflow: 'auto',overflowX:'hidden'}}>
        {/* <div className={`main-content ${isSidebarOpen ? 'with-sidebar' : ''}`}> */}
        <Router>
          <div className="app-container" color='lightyellow'>
            {/* <div className={`app-container ${isSidebarOpen ? 'sidebar-open' : ''}`}> */}
            <table style={{ borderSpacing: 0 }}>
              <tr>

                {/* <table> */}
                <td>
                  <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`} style={{ height: 'calc(100vh - 111px)'}}>
                    {/* <div className="top-bar" height="5px">
                      <header style={headerStyle}>Version 1.0</header>
                    </div> */}
                    <Select
                      //value={reportLink}
                      onChange={handleChange}
                      options={stationOptions}
                      styles={selectStyles}
                      placeholder="Search Report"
                    />
                    <div>
                      <SideBar isOpen={isSidebarOpen} />
                    </div>
                    {/* <div className="bottom-bar" height='5px'>
                      <header style={footerStyle}>Copyright © CRIS</header>
                    </div> */}
                  </div>
                </td>
                {/* </table> */}

                <td>
                  <div className="content" color='red' style={{width: 'calc(100vw - 330px)'}}  >
                    <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="/TrainServiceSummary" element={<TrainServiceSummary />} />
                      <Route path="/TrainsBetweenStations" element={<TrainsBetweenStations />} />
                      <Route path="/DataEntryStatus" element={<DataEntryStatus />} />
                      <Route path="/GaugeDiscrepency" element={<GaugeDiscrepency />} />
                      <Route path="/TractionDiscrepency" element={<TractionDiscrepency />} />
                      <Route path="/JunctionDiscrepency" element={<JunctionDiscrepency />} />
                      <Route path="/PttTimingsByZoneDivision" element={<PttTimingsByZoneDivision />} />
                      <Route path="/ICTimingsByZoneDivision" element={<ICTimingsByZoneDivision />} />
                      <Route path="/PassingTrainTimingsByZoneDivision" element={<PassingTrainTimingsByZoneDivision />} />
                      <Route path="/TrainTractionSummary" element={<TrainTractionSummary />} />
                      <Route path="/ZonewiseExtraTimeConsumed" element={<ZonewiseExtraTimeConsumed />} />
                      <Route path="/DivisionwiseExtraTimeConsumed" element={<DivisionwiseExtraTimeConsumed />} />
                      <Route path="/TrainwiseExtraTimeConsumed" element={<TrainwiseExtraTimeConsumed />} />
                      <Route path="/TrainWiseWttStoppages" element={<TrainWiseWttStoppages />} />
                      <Route path="/TrainWisePttStoppages" element={<TrainWisePttStoppages />} />
                      <Route path="/StationwisePttStoppages" element={<StationwisePttStoppages />} />
                      <Route path="/StationwiseWttStoppages" element={<StationwiseWttStoppages />} />
                      <Route path="/LongStoppageZoneSummary" element={<LongStoppageZoneSummary />} />
                      <Route path="/LongStoppageDivisionSummary" element={<LongStoppageDivisionSummary />} />
                      <Route path="/LongStoppageStationwiseDetail" element={<LongStoppageStationwiseDetail />} />
                      <Route path="/TrainHistoryComparision" element={<TrainHistoryComparision />} />
                      <Route path="/TrainComparisionZonewise" element={<TrainComparisionZonewise />} />
                      <Route path="/TrainSpeedBreakup" element={<TrainSpeedBreakup />} />
                      <Route path="/NonStopLongRuntimeTrains" element={<NonStopLongRuntimeTrains />} />
                      <Route path="/ZonalSummaryComparision" element={<ZonalSummaryComparision />} />
                      <Route path="/DivisionalSummaryComparision" element={<DivisionalSummaryComparision />} />

                      <Route path="/InfraZoneMaster" element={<InfraZoneMaster />} />
                      <Route path="/InfraDivisionMaster" element={<InfraDivisionMaster />} />
                      <Route path="/InfraStationMaster" element={<InfraStationMaster />} />
                      <Route path="/StationLine" element={<StationLine />} />
                      <Route path="/StationChangePoint" element={<StationChangePoint />} />
                      <Route path="/InfraBlockSectionMaster" element={<InfraBlockSectionMaster />} />
                      <Route path="/InfraBlockSectionLineMaster" element={<InfraBlockSectionLineMaster />} />
                      <Route path="/InfraSectionMaster" element={<InfraSectionMaster />} />
                      <Route path="/InfraSectionStations" element={<InfraSectionStations />} />
                      <Route path="/InfraBoardMaster" element={<InfraBoardMaster />} />
                      <Route path="/InfraBoardStation" element={<InfraBoardStation />} />
                      <Route path="/StationPlatfomDetail" element={<StationPlatfomDetail />} />

                      <Route path="/MapNodes" element={<MapNodes />} />
                      <Route path="/MapLinks" element={<MapLinks />} />
                      <Route path="/MapInternalNodes" element={<MapInternalNodes />} />
                      <Route path="/MapRouteExceptions" element={<MapRouteExceptions />} />
                      <Route path="/MapZonalNodes" element={<MapZonalNodes />} />
                      <Route path="/BlockCorridor" element={<BlockCorridor />} />
                      <Route path="/BlockSectionPsr" element={<BlockSectionPsr />} />
                      <Route path="/BlockSectionGradient" element={<BlockSectionGradient />} />
                      <Route path="/BlockSectionCurvature" element={<BlockSectionCurvature />} />
                      <Route path="/ProposalFilter" element={<ProposalFilter />} />
                      <Route path="/StationChnagePoint" element={<StationChnagePoint />} />
                      <Route path="/StationTimeTable" element={<StationTimeTable />} />
                      <Route path="/ZoneTractionSummary" element={<ZoneTractionSummary />} />
                      <Route path="/DivisionTractionSummary" element={<DivisionTractionSummary />} />
                      <Route path="/TrafficDensity" element={<TrafficDensity />} />

                      <Route path="/TimeTableSummary" element={<TimeTableSummary />} />
                      <Route path="/TimeTableZonalSummary" element={<TimeTableZonalSummary />} />
                      <Route path="/TimeTableDivisionalSummary" element={<TimeTableDivisionalSummary />} />

                    </Routes>
                  </div>
                </td>

              </tr>
            </table>
          </div>
        </Router>

      </div>
      <div className="bottom-bar" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <header style={footerStyle}>Copyright © CRIS</header>
        <header style={footerStyle}>Software Aided Train Scheduling And Netwrok Governance</header>
        <header style={footerStyle}>Version 1.0</header>
      </div>
    </div>
  );
};

export default TopBottomBar;
