import React from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import imageSrc from '../images/PDF.png';

const PdfFile = ({ pdfData, columnDefinition, columnOrder, fileName }) => {

    const fileExtension = '.pdf';

  const generatePdf = () => {
    
    if (pdfData.length === 0) {
      alert('No data available for export to PDF');
      return;
    }

    const doc = new jsPDF({
      orientation: 'landscape'
    });

    const headerData = columnDefinition.map(column => column.headerName);

    const formattedRowData = pdfData.map(row =>
      columnOrder.map(key => row[key])
    );

    doc.autoTable({
      head: [headerData],
      body: formattedRowData,
      styles: {
        fontSize: 12,
        textColor: [0, 0, 0],
        cellWidth: 'wrap'
      }
    });

    doc.save(fileName + fileExtension);
  };

  return (
    <button onClick={generatePdf} style={{ cursor: "pointer", fontSize: 14, height: '40px', width: '40px', backgroundColor: 'transparent', padding: 0, border: 'none' }}>
      <img src={imageSrc} alt="Export to PDF" style={{ width: '100%', height: '100%' }} />
    </button>
  );
};

export default PdfFile;
