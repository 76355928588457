import React, { useState } from 'react';
//import { useNavigate } from 'react-router-dom';

const LoginPage = ({onLogin}) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [captcha, setCaptcha] = useState(generateRandomCaptcha());
  const [userCaptcha, setUserCaptcha] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  //const navigate = useNavigate();

  function generateRandomCaptcha() {
    const characters = 'A0123456789';
    // const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+';
    let captcha = '';
    for (let i = 0; i < 5; i++) {
      captcha += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return captcha;
  }

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleCaptchaChange = (e) => {
    setUserCaptcha(e.target.value);
  };

  const handleRefreshCaptcha = () => {
    // Refresh the captcha
    setCaptcha(generateRandomCaptcha());
    setUserCaptcha('');
  };

  // const handleLoginSubmit = (e) => {
  //   e.preventDefault();

  //   // Check if the captcha is correct
  //   if (userCaptcha.toUpperCase() !== captcha.toUpperCase()) {
  //     setErrorMessage('Incorrect captcha. Please try again.');
  //     return;
  //   }

    
  //   // Check if the username and password are correct
  //   if ((username === 'cris1' && password === 'sats1') || (username === 'cris2' && password === 'sats2')) {
  //     // Perform login logic here (for demo, just logging the inputs)
  //     console.log('Username:', username);
  //     console.log('Password:', password);
  //     navigate('/DashBoard');
  //   } else {
  //     setErrorMessage('Incorrect username or password. Please try again.');
  //   }
  // };



  const handleLoginSubmit = async (e) => {
    e.preventDefault();
  
    // Check if the captcha is correct
    if (userCaptcha.toUpperCase() !== captcha.toUpperCase()) {
      setErrorMessage('Incorrect captcha. Please try again.');
      return;
    }
  
    try {

     // const password = password;
       const encodedPassword = encodePasswordToBase64(password.toUpperCase());
      console.log('Encoded Password:', encodedPassword);

      // const response = await fetch(`http://localhost:8082/LoginZone/${username}/${password}`);
     // const response = await fetch(`http://localhost:8082/LoginZone/${username}/${encodedPassword}`);
      //const response = await fetch(`${process.env.REACT_APP_INFRA_SERVICE_ADDRESS}/LoginZone/${username}/${encodedPassword}`);
      const apiUrl = `${process.env.REACT_APP_INFRA_SERVICE_ADDRESS}/LoginZone/${username.toUpperCase()}/${encodedPassword}`;
      const response = await fetch(apiUrl);
      console.log('Encoded Password:', apiUrl);
      const data = await response.json();
  
      if (data[0].loginMessage==="OK") {
        // Assuming the API returns a boolean indicating successful login
        if (data[0].loginMessage==="OK") {
          // Perform login logic here (for demo, just logging the inputs)
          console.log('Username:', username);
          console.log('Password:', password);
          //navigate('/TopBottomBar');
          const user = response;
          console.log('logintest',user);
         onLogin(user);
        } else {
          setErrorMessage('Incorrect username or password. Please try again.');
        }
      } else {
        // Handle non-OK response (e.g., server error)
        console.error('Server error:', data.message);
        setErrorMessage('Incorrect username or password. Please try again.');
      }
    } catch (error) {
      // Handle network-related errors
      console.error('Network error:', error);
      setErrorMessage('Unable to connect to the server. Please try again later.');
    }
  };
  

  function encodePasswordToBase64(password) {
    try {
      const encodedData = btoa(unescape(encodeURIComponent(password)));
      return encodedData;
    } catch (ex) {
      throw new Error("Error in base64Encode: " + ex.message);
    }
  }
  
  

  const captchaImageStyle = {
    position: 'relative',
    marginRight: '0.5rem',
    color: '#fff',  // Text color
    background: '#007bff',  // Background color
    padding: '8px',  // Padding around the text
    borderRadius: '4px',
    fontStyle: 'italic',  // Make the text italic
  };
  
  
  const strikeLineStyle = {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: 0,
    right: 0,
    height: '1px',
    background: '#fff',  // Color of the strike line
  };
  
  // const strikeLineStyle = {
  //   content: '""',
  //   position: 'absolute',
  //   top: '50%',
  //   left: 0,
  //   right: 0,
  //   height: '2px',  // Adjust the height of the line
  //   background: '#fff',  // Color of the strike line
  //   borderRadius: '10px',  // Add border-radius for a curved line
  //   transform: 'rotate(-45deg)',  // Adjust the rotation angle
  // };
  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '80vh',
    backgroundColor: '#f4f4f4',
  };

  const formStyle = {
    textAlign: 'center',
    padding: '2rem',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    backgroundColor: '#ffffff',
    boxShadow: '0px 5px 20px 0px #d0d0d0',
    width: '400px',
  };

  const inputStyle = {
    width: '80%',
    padding: '1rem',
    marginTop: '1rem',
    marginBottom: '1rem',
    borderRadius: '4px',
    border: '1px solid #ced4da',
    backgroundColor: '#f8f9fa',
    color: '#495057',
  };

  const buttonStyle = {
    width: '40%',
    padding: '1rem',
    borderRadius: '4px',
    backgroundColor: '#007bff',
    color: '#ffffff',
    cursor: 'pointer',
  };

  const errorMessageStyle = {
    color: '#dc3545',
    marginTop: '1rem',
    marginBottom: '1rem',
    height: '3rem',
    textAlign: 'center',
  };

  const labelStyle = {
    display: 'block',
    textAlign: 'left',
    marginBottom: '0.5rem',
  };

  return (
    <div style={containerStyle}>
      <form onSubmit={handleLoginSubmit} style={formStyle}>
        <div>
          <label htmlFor="username" style={labelStyle}>
            Username:
          </label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={handleUsernameChange}
            required
            style={inputStyle}
          />
        </div>
        <div>
          <label htmlFor="password" style={labelStyle}>
            Password:
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
            required
            style={inputStyle}
          />
        </div>
        <div>
          <label htmlFor="captcha" style={labelStyle}>
            Captcha:
          </label>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '1rem' }}>
            <span style={captchaImageStyle}>
              {captcha}
              <div style={strikeLineStyle}></div>
            </span>
            <button
              type="button"
              onClick={handleRefreshCaptcha}
              style={{ ...buttonStyle, backgroundColor: '#6c757d' }}
            >
              Refresh
            </button>
          </div>
        </div>
        <div>
          <input
            type="text"
            id="captcha"
            value={userCaptcha}
            onChange={handleCaptchaChange}
            required
            style={inputStyle}
          />
        </div>
        <div style={errorMessageStyle}>{errorMessage}</div>
        <button type="submit" style={buttonStyle}>
          Login
        </button>
      </form>
    </div>
  );
};

export default LoginPage;



