import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';
import SelectDivision from '../../components/SelectDivision';
import SelectZone from '../../components/SelectZone';
import ExportToExcel from '../../components/ExcelFile';
import PdfFile from '../../components/PdfFile';

const InfraStationMaster = () => {

  const [rowData, setRowData] = useState([]);
  const [selectedZone, setSelectedZone] = useState(null);
  const [selectedDivision, setSelectedDivision] = useState(null);

  const handleSelectZone = (selectedZone) => {
    setSelectedZone(selectedZone.value);
  };

  const handleSelectDivision = (selectedDivision) => {
    setSelectedDivision(selectedDivision.value);
  };

  const handleButtonClick = () => {

    if (selectedZone && selectedDivision) {
      // Fetch data from the API
      axios.get(`${process.env.REACT_APP_INFRA_SERVICE_ADDRESS}/stationlist/${selectedZone}/${selectedDivision}`)
        .then(response => {
          // Set the fetched data to the state
          setRowData(response.data);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }
  };

  const gridOptions = {
    getRowHeight: () => 20, // Set a fixed row height of 20 pixels
    // Other grid options...
  };

  const columnDefs = [
    { headerName: 'Zone', field: 'zoneCode',hide:true },
    { headerName: 'Division', field: 'dvsnCode',hide:true },
    { headerName: 'Station Code', field: 'sttnCode' },
    { headerName: 'Station Name', field: 'sttnName' },
    { headerName: 'Constituency', field: 'sttnConstituency' },
    { headerName: 'Civil District', field: 'sttnCivilDistic' },
    { headerName: 'Gauge', field: 'sttnGauge' },
    { headerName: 'Class', field: 'sttnClass' },
    { headerName: 'Junction', field: 'junction' },
    { headerName: 'Division Interchange', field: 'dvsnInterChange' },
    { headerName: 'Traction Change', field: 'tractionChnage' },
    { headerName: 'Crew Change', field: 'crewChange' },
    { headerName: 'Freeze Status', field: 'frezeStatus' },
    { headerName: 'Platform Freeze', field: 'platFormFreze' },
    { headerName: 'Station Interlocking', field: 'sttnInterLocking' },
    { headerName: 'Station Signal Type', field: 'sttnSignalType' },
  ];

  const columnOrder = ['zoneCode','dvsnCode','sttnCode', 'sttnName','sttnConstituency', 'sttnCivilDistic', 'sttnGauge', 'sttnClass', 'junction', 'dvsnInterChange', 'tractionChnage',
    'crewChange', 'frezeStatus', 'platFormFreze', 'sttnInterLocking', 'sttnSignalType'];

  const reorderedData = rowData.map(item => {
    const reorderedItem = {};
    columnOrder.forEach(key => {
      if (item.hasOwnProperty(key)) {
        reorderedItem[key] = item[key];
      }
    });
    return reorderedItem;
  });

  return (
    <div className="ag-theme-alpine" style={{ height: '650px'}}>
      <div>
        <table>
          <tr>
            <td style={{ width: '210px' }}>
              <SelectZone onSelectChange={handleSelectZone} />
            </td>
            <td style={{ width: '210px' }}>
              {selectedZone && (
                <SelectDivision selectedValue={selectedZone} onSelectChange={handleSelectDivision} />
              )}
            </td>
            <td> <button onClick={handleButtonClick}>Fetch Data</button></td>
            <td> <PdfFile pdfData={rowData} columnDefinition={columnDefs} columnOrder={columnOrder} fileName="StationMaster" /> </td>
            <td> <ExportToExcel excelData={reorderedData} fileName="StationMaster" /> </td>
          </tr>
        </table>
      </div>

      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        //domLayout='autoHeight'
        autoSizeColumns={true}
        rowSelection="multiple"
        gridOptions={gridOptions}
      />

    </div>
  );

};

export default InfraStationMaster;