import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';
import timeInHHMMSS from '../../functions/TimeInHHMMSS';

const TrainHistoryComparision = () => {
   
    const [rowData, setRowData] = useState([]);

    useEffect(() => {
        // Fetch data from the API
        axios.get(`${process.env.REACT_APP_REPORT_SERVICE_ADDRESS}/TrainHistorycomparison/2024-02-07/2023-02-07/NR/DLI`)
          .then(response => {
            // Set the fetched data to the state
            setRowData(response.data);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
      }, []);
    

    const columnDefs = [               
        { headerName: 'Train Number', field: 'trainNumber'},
        { headerName: 'Train Id', field: 'trainId'},
        { headerName: 'Train Type', field: 'trainType'},
        { headerName: 'Train Speed', field: 'trainSpeed'},
        { headerName: 'Train Distance', field: 'trainDistance'},
        { headerName: 'Section Code', field: 'section'},
        { headerName: 'Entry Time', field: 'entryTime', valueFormatter: timeInHHMMSS},
        { headerName: 'Exit Time', field: 'exitTime', valueFormatter: timeInHHMMSS},
        { headerName: 'Runtime', field: 'runTime'},
        { headerName: 'Stoppage Time', field: 'stoppageTime'},
        { headerName: 'Stoppage Count', field: 'stoppageCount'},
        { headerName: 'Acc Time', field: 'accTime'},
        { headerName: 'Dcc Time', field: 'decTime'},
        { headerName: 'Extra Time', field: 'extraTime'},
        { headerName: 'Traffice Allowance', field: 'trfcAllowance'},
        { headerName: 'Traffice Allowance Permitted', field: 'trfcAllowancePermitted'},
        { headerName: 'Engineering Allowance', field: 'enggAllowance'},
        { headerName: 'Engineering Allowance Permitted', field: 'enggAllowancePermitted'},
        { headerName: 'Section Speed', field: 'sectionSpeed'},
        { headerName: 'Travel Time', field: 'travelTime'},
        { headerName: 'Train Number Next', field: 'trainNumberNext'},
        { headerName: 'Train Id Next', field: 'trainIdNext'},
        { headerName: 'Train Type Next', field: 'trainTypeNext'},
        { headerName: 'Train Speed Next', field: 'trainSpeedNext'},
        { headerName: 'Train Distance Next', field: 'trainDistanceNext'},
        { headerName: 'Section Code Next', field: 'sectionNext'},
        { headerName: 'Entry Time Next', field: 'entryTimeNext', valueFormatter: timeInHHMMSS},
        { headerName: 'Exit Time Next', field: 'exitTimeNext', valueFormatter: timeInHHMMSS},
        { headerName: 'Runtime Next', field: 'runTimeNext'},
        { headerName: 'Stoppage Time Next', field: 'stoppageTimeNext'},
        { headerName: 'Stoppage Count Next', field: 'stoppageCountNext'},
        { headerName: 'Acc Time Next', field: 'accTimeNext'},
        { headerName: 'Dcc Time Next', field: 'decTimeNext'},
        { headerName: 'Extra Time Next', field: 'extraTimeNext'},
        { headerName: 'Traffice Allowance Next', field: 'trfcAllowanceNext'},
        { headerName: 'Traffice Allowance Permitted Next', field: 'trfcAllowancePermittedNext'},
        { headerName: 'Engineering Allowance Next', field: 'enggAllowanceNext'},
        { headerName: 'Engineering Allowance Permitted Next', field: 'enggAllowancePermittedNext'},
        { headerName: 'Section Speed Next', field: 'sectionSpeedNext'},
        { headerName: 'Travel Time Next', field: 'travelTimeNext'},
        { headerName: 'Time Saved', field: 'timeSaved'},
        { headerName: 'Speed Gain', field: 'speedGain'},
        { headerName: 'Speed Gain Percentage', field: 'speedGainPercent'},
        { headerName: 'Stoppage Reduce', field: 'stoppageReduced'},
        { headerName: 'Stoppage Time Reduce', field: 'stoppageTimeReduced'},
    ];
        

  return (
    <div className="ag-theme-alpine" style={{ height: '600px', width: '100%' }}>        
      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        //domLayout='autoHeight'
        autoSizeColumns={true}
        rowSelection="multiple"
      />
    </div>
  );

};

export default TrainHistoryComparision;