import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';
import SelectDivision from '../../components/SelectDivision';
import SelectZone from '../../components/SelectZone';
import SelectSection from '../../components/SelectSection';
import ExportToExcel from '../../components/ExcelFile';
import PdfFile from '../../components/PdfFile';


const BlockCorridor = () => {

    const [rowData, setRowData] = useState([]);
    const [selectedZone, setSelectedZone] = useState(null);
    const [selectedDivision, setSelectedDivision] = useState(null);
    const [selectedSection, setSelectedSection] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleSelectZone = (selectedZone) => {
      console.log("Selected Zone:", selectedZone.value);

      setSelectedZone(selectedZone.value);
    };

    const handleSelectDivision = (selectedDivision) => {
      console.log("Selected Division:", selectedDivision.value);
      setSelectedDivision(selectedDivision.value);
    };

    const handleSelectStation = (selectedSection) => {
      console.log("Selected Station:", selectedSection.value);
      setSelectedSection(selectedSection.value);
    };

    const handleButtonClick = () => {
      setLoading(true);   
      if (selectedZone && selectedDivision && selectedSection) {
        // Fetch data from the API
        axios.get(`${process.env.REACT_APP_INFRA_SERVICE_ADDRESS}/BlockCorridor/${selectedZone}/${selectedDivision}/${selectedSection}`)
          .then(response => {
            // Set the fetched data to the state
            setRowData(response.data);
            setLoading(false);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
            setLoading(false);
          });
      }
    };
      
    const columnDefs = [
        { headerName: 'Zone', field: 'zoneCode',hide:true },
        { headerName: 'Division', field: 'dvsnCode',hide:true },
        { headerName: 'Section', field: 'sectionCode',hide:true },
        { headerName: 'Section Seq', field: 'sectionSeq' },
        { headerName: 'Block Section', field: 'blockSctn' },
        { headerName: 'Direction', field: 'blockSctnDirection' },
        { headerName: 'Line Number', field: 'blockSctnLineNumber' },
        { headerName: 'Days of Service', field: 'corridorDaysofService' },
        { headerName: 'Corridor Id', field: 'corridorId',hide:true },
        { headerName: 'From Time', field: 'corridorFromTime' },
        { headerName: 'To Time', field: 'corridorToTime' },
        { headerName: 'From Date', field: 'corridorFromDate' },
        { headerName: 'To Date', field: 'corridorToDate' },
        { headerName: 'P/T', field: 'corridorPermanent' },
        { headerName: 'Start Buffer', field: 'corridorStartBuffer' },
        { headerName: 'End Buffer', field: 'corridorEndBuffer' },
        { headerName: 'Description', field: 'corridorDescription' },
        { headerName: 'Remarks', field: 'corridorRemarks' },
      ];
    
      const columnOrder = ['zoneCode', 'dvsnCode', 'sectionCode', 'sectionSeq', 'blockSctn', 'blockSctnDirection', 'blockSctnLineNumber', 'corridorDaysofService', 'corridorId',
                           'corridorFromTime','corridorToTime','corridorFromDate','corridorToDate','corridorPermanent','corridorStartBuffer','corridorEndBuffer','corridorDescription',
                           'corridorRemarks'];

  const reorderedData = rowData.map(item => {
    const reorderedItem = {};
    columnOrder.forEach(key => {
      if (item.hasOwnProperty(key)) {
        reorderedItem[key] = item[key];
      }
    });
    return reorderedItem;
  });

  return (
    <div className="ag-theme-alpine" style={{ height: '650px'}}>
        <div>
        <table>
          <tr>
            <td style={{ width: '210px' }}>
              <SelectZone onSelectChange={handleSelectZone} />
            </td>
            <td style={{ width: '210px' }}>
              {selectedZone && <SelectDivision selectedValue={selectedZone} onSelectChange={handleSelectDivision} />}
            </td>
            <td style={{ width: '210px' }}>
              {selectedZone && selectedDivision && <SelectSection selectedValue={selectedZone} selectedValue1={selectedDivision} onSelectChange={handleSelectStation} />}
            </td>
            <td> <button onClick={handleButtonClick}>Fetch Data</button> </td>

            <td> <PdfFile pdfData={rowData} columnDefinition={columnDefs} columnOrder={columnOrder} fileName="StationTimeTable" /></td>

            <td> <ExportToExcel excelData={reorderedData} fileName="example_data" /> </td>
          </tr>
        </table>
      </div>    

      {loading ? (
        // Show a progress bar while loading data       
        <progress style={{ width: '100%', height: '30px',}} />
      ) : (
      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        //domLayout='autoHeight'
        autoSizeColumns={true}
        rowSelection="multiple"
      />
      )}
    </div>
  );

};

export default BlockCorridor;