const timeDayInHHMMSS = ({ value }) => {
    if (value) {

        const numericValue = typeof value === 'string' ? parseInt(value, 10) : value;
        
      const DayOfRun = Math.floor(Math.floor(numericValue) / 86400) + 1;
      const hours = Math.floor((numericValue % 86400) / 3600);
      const minutes = Math.floor((numericValue % 3600) / 60);
      const seconds = numericValue % 60;

      return `${hours.toString().padStart(2, '0')} :
              ${minutes.toString().padStart(2, '0')} :
              ${seconds.toString().padStart(2, '0')} -
              ${DayOfRun.toString().padStart(2, '0')}`;
    }

    return value.toString(); // Convert non-numeric values to string
  };

  export default timeDayInHHMMSS;