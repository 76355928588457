import React, { useEffect, useState, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';
import ExportToExcel from '../../components/ExcelFile';
import PdfFile from '../../components/PdfFile';
import StatusBar from '../../components/StatusBar';

const DivisionalSummary = () => {

    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [ttProfilesSum, setTtProfilesSum] = useState(0);
    const [ttProfilesUpdated, setTtProfilesUpdated] = useState(0);
    const [ttProfilesUpdatedPercent, setTtProfilesUpdatedPercent] = useState(0);
    const [ttProfilesConsistent, setTtProfilesConsistent] = useState(0);
    const [ttProfilesConsistentPercent, setTtProfilesConsistentPercent] = useState(0);
    const [ttProfilesConfirmed, setTtProfilesConfirmed] = useState(0);
    const [ttProfilesConfirmedPercent, setTtProfilesConfirmedPercent] = useState(0);
    const gridApiRef = useRef(null);

    useEffect(() => {
        setLoading(true);
        // Fetch data from the API
        axios.get(`${process.env.REACT_APP_REPORT_SERVICE_ADDRESS}/TimeTableDivisionalSummary/JPTT`)
            .then(response => {
                // Set the fetched data to the state
                setRowData(response.data);
                setLoading(false);

                const filteredData = response.data;

                const sum = filteredData.reduce((acc, item) => acc + (parseFloat(item.proposalCount) || 0), 0);
                setTtProfilesSum(sum);
                
                const updated = filteredData.reduce((acc, item) => acc + (parseFloat(item.proposalModify) || 0), 0);
                setTtProfilesUpdated(updated);
                
                const updatedPercent = sum > 0 ? (updated / sum) * 100 : 0;
                setTtProfilesUpdatedPercent(updatedPercent.toFixed(2));

                const consistent = filteredData.reduce((acc, item) => acc + (parseFloat(item.proposalInconsistent) || 0), 0);
                setTtProfilesConsistent(consistent);

                const consistentPercent = sum > 0 ? (consistent / sum) * 100 : 0;
                setTtProfilesConsistentPercent(consistentPercent.toFixed(2));

                const confirmed = filteredData.reduce((acc, item) => acc + (parseFloat(item.proposalFrozen) || 0), 0);
                setTtProfilesConfirmed(confirmed);

                const confirmedPercent = sum > 0 ? (confirmed / sum) * 100 : 0;
                setTtProfilesConfirmedPercent(confirmedPercent.toFixed(2));
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }, []);

    const columnDefs = [
        { headerName: 'Zone', field: 'zoneCode' },
        { headerName: 'Division', field: 'dvsnCode' },
        { headerName: 'TT Profiles', field: 'proposalCount' },
        { headerName: 'Updated', field: 'proposalModify' },
        { headerName: 'Consistent', field: 'proposalInconsistent' },
        { headerName: 'Confirmed', field: 'proposalFrozen' },
        { headerName: '% Updated', field: 'proposalNotModifyPercent' },
        { headerName: '% Consistent', field: 'proposalInconsistentPercent' },
        { headerName: '% Confirmed', field: 'proposalFrozenPercent' },
    ];

    const onGridReady = (params) => {
        gridApiRef.current = params.api;
    };

    const adjustColumnWidths = () => {
        if (gridApiRef.current) {
            const allColumnIds = [];
            const columnDefs = gridApiRef.current.getColumnDefs();

            // Get all column ids
            columnDefs.forEach((column) => {
                allColumnIds.push(column.field);
            });

            // Iterate over each row to determine the maximum width for each column
            const maxWidths = {};
            rowData.forEach((row) => {
                allColumnIds.forEach((colId) => {
                    const value = row[colId] ? String(row[colId]).length : 0;
                    maxWidths[colId] = Math.max(maxWidths[colId] || 0, value);
                });
            });

            // Update column definitions with adjusted widths
            const updatedColumnDefs = columnDefs.map((column) => {
                const maxWidth = maxWidths[column.field] * 8 + 100; // Adjust based on your font size and padding
                return { ...column, width: maxWidth };
            });

            // Set the updated column definitions
            gridApiRef.current.setColumnDefs(updatedColumnDefs);
        }
    };


    const gridOptions = {
        getRowHeight: () => 20, // Set a fixed row height of 20 pixels
        // Other grid options...
    };

    const columnOrder = ['zoneCode', 'dvsnCode', 'proposalCount', 'proposalModify', 'proposalInconsistent', 'proposalFrozen', 'proposalNotModifyPercent', 'proposalInconsistentPercent', 'proposalFrozenPercent'];

    const reorderedData = rowData.map(item => {
        const reorderedItem = {};
        columnOrder.forEach(key => {
            if (item.hasOwnProperty(key)) {
                reorderedItem[key] = item[key];
            }
        });
        return reorderedItem;
    });

    return (
        <div className="ag-theme-alpine" style={{ height: '550px'}}>

            <td>
                <PdfFile pdfData={rowData} columnDefinition={columnDefs} columnOrder={columnOrder} fileName="TimeTableDivisionalSummary" />
</td>
<td>
                <ExportToExcel excelData={reorderedData} fileName="TimeTableDivisionalSummary" />
            </td>

            {loading ? (
                // Show a progress bar while loading data       
                <progress style={{ width: '100%', height: '30px', }} />
            ) : (
                <AgGridReact
                    columnDefs={columnDefs}
                    rowData={rowData}
                    //domLayout='autoHeight'
                    // autoSizeColumns={true}
                    // rowSelection="multiple"
                    onGridReady={onGridReady}
                    onFirstDataRendered={adjustColumnWidths}
                    gridOptions={gridOptions}
                />
            )}

            <table style={{ width: '100%', padding: '20px', margin: 'auto', borderCollapse: 'collapse' }}>
                <tr style={{padding:0,margin:0}}>
                    <td style={{width:100, padding: 0, margin: 0}}>Updated</td>
                    <td style={{padding:0,margin:0}}><StatusBar width={ttProfilesUpdatedPercent} backgroundColor='yellow' color='black' /></td>
                </tr>
                <tr style={{padding:0,margin:0}}>
                    <td style={{width:100, padding: 0, margin: 0}}>Consistent</td>
                    <td style={{padding:0,margin:0}}><StatusBar width={ttProfilesConsistentPercent} backgroundColor='blue' color='white' /></td>
                </tr>
                <tr style={{padding:0,margin:0}}>
                    <td style={{width:100, padding: 0, margin: 0}}>Confirmed</td>
                    <td style={{padding:0,margin:0}}><StatusBar width={ttProfilesConfirmedPercent} backgroundColor='green' color='white' /></td>
                </tr>
            </table>

        </div>
    );

};

export default DivisionalSummary;