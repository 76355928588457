import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';
import SelectZone from '../../components/SelectZone';
import FormateDate from '../../functions/FormateDate';

const TrainTractionSummary = () => {
   
    const [rowData, setRowData] = useState([]);
    const [selectedZone, setSelectedZone] = useState(null);
    
  const handleSelectZone = (selectedZone) => {
    setSelectedZone(selectedZone.value);
  };


  const handleButtonClick = () => {
    
    if (selectedZone) {
      // Call the additional API using all three selected values
      axios
        .get(`${process.env.REACT_APP_INFRA_SERVICE_ADDRESS}/TrainTracktionSummary/${selectedZone}`)
        .then((response) => {
          // Set the additional data to state
          setRowData(response.data);
        })
        .catch((error) => {
          console.error('Error fetching additional data:', error);
        });
    }
  };

  const columnDefs = [         
    { headerName: 'Train Number', field: 'trainnumber', filter: true},      
    { headerName: 'Train Id', field: 'trainid'},
    { headerName: 'Train Type', field: 'traintype'},
    { headerName: 'Valid From', field: 'validfrom',valueFormatter: ({ value }) => FormateDate(value)},
    { headerName: 'Valid To', field: 'validto',valueFormatter: ({ value }) => FormateDate(value)},
    { headerName: 'Electrified', field: 'electrified'},
    { headerName: 'Electrified Percentage', field: 'electrifiedPercent'},
    { headerName: 'Non Electrified', field: 'nonElectrified'},
    { headerName: 'Non Electrified Percentage', field: 'nonElectrifiedPercent'},
];
        

  return (
    <div className="ag-theme-alpine" style={{ height: '600px', width: '100%' }}>
        <div>             
          <td style={{ width: '210px' }}>
              <SelectZone onSelectChange={handleSelectZone} />
          </td>          
          <td>
              <button onClick={handleButtonClick}>Fetch Data</button>
          </td>
      </div>
      
      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        //domLayout='autoHeight'
        autoSizeColumns={true}
        rowSelection="multiple"
      />
    </div>
  );

};

export default TrainTractionSummary;