import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';
import Calendar from '../../components/Calendar';
import ExportToExcel from '../../components/ExcelFile';
import PdfFile from '../../components/PdfFile';

const DivisionalSummaryComparision = () => {

  const [rowData, setRowData] = useState([]);
  const [selectedPreviousDate, setSelectedPreviousDate] = useState(null);
  const [selectedNextDate, setSelectedNextDate] = useState(null);
  const [loading, setLoading] = useState(false);

  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };

  const handlePrevDateSelect = (selectedPrvDate) => {

    const formattedPrvDate = selectedPrvDate.toLocaleDateString('en-GB', options).replace(/\//g, '-');
    // Use the selectedDate in your API call
    //console.log("Selected Date:", selectedPrvDate.toISOString().split('T')[0]);
    console.log("Selected Date:", formattedPrvDate);
    // Make your API call here with the selectedDate as an argument

    if (selectedPrvDate) {
      setSelectedPreviousDate(formattedPrvDate); // Format the date as YYYY-MM-DD            
    }
  };

  const handleNextDateSelect = (selectednextDate) => {

    const formattedNextDate = selectednextDate.toLocaleDateString('en-GB', options).replace(/\//g, '-');

    // Use the selectedDate in your API call
    //console.log("Selected Date:", selectednextDate.toISOString().split('T')[0]);
    console.log("Selected Date:", formattedNextDate);
    // Make your API call here with the selectedDate as an argument

    if (selectednextDate) {
      setSelectedNextDate(formattedNextDate); // Format the date as YYYY-MM-DD            
    }
  };

  const handleButtonClick = () => {

    setLoading(true);

    if (selectedPreviousDate === selectedNextDate) {
      console.log('Incorrect Date.');
      console.log(selectedPreviousDate);
      return;
    }

    if (selectedPreviousDate && selectedNextDate) {
      axios.get(`${process.env.REACT_APP_REPORT_SERVICE_ADDRESS}/ZonalSummaryComparison/${selectedPreviousDate}/${selectedNextDate}`)
        .then(response => {
          // Set the fetched data to the state
          setRowData(response.data);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          setLoading(false);
        });
    }
  };

  const columnDefs = [
    { headerName: 'Zone', field: 'zoneCode' },
    { headerName: 'Division', field: 'dvsnCode' },
    { headerName: 'Runtime', field: 'runTime' },
    { headerName: 'Stoppage Time', field: 'stoppageTime' },
    { headerName: 'Stoppage Count', field: 'stoppageCount' },
    { headerName: 'Acc Time', field: 'accTime' },
    { headerName: 'Dcc Time', field: 'decTime' },
    { headerName: 'Engg Allowance', field: 'enggAllowance' },
    { headerName: 'Engg Allowance Permitted', field: 'enggAllowancePermitted' },
    { headerName: 'Traffic Allowance', field: 'trfcAllowance' },
    { headerName: 'Traffic Allowance Permitted', field: 'trfcAllowancePermitted' },
    { headerName: 'Extra Time', field: 'extraTime' },
    { headerName: 'Train Distance', field: 'trainDistance' },
    { headerName: 'Travel Time', field: 'travelTime' },
    { headerName: 'Section Speed', field: 'sectionSpeed' },
    { headerName: 'Zone Next', field: 'zoneCodeNext' },
    { headerName: 'Division Next', field: 'dvsnCodeNext' },
    { headerName: 'Runtime Next', field: 'runTimeNext' },
    { headerName: 'Stoppage Time Next', field: 'stoppageTimeNext' },
    { headerName: 'Stoppage Count Next', field: 'stoppageCountNext' },
    { headerName: 'Acc Time Next', field: 'accTimeNext' },
    { headerName: 'Dcc Time Next', field: 'decTimeNext' },
    { headerName: 'Engg Allowance Next', field: 'enggAllowanceNext' },
    { headerName: 'Engg Allowance Permitted Next', field: 'enggAllowancePermittedNext' },
    { headerName: 'Traffic Allowance Next', field: 'trfcAllowanceNext' },
    { headerName: 'Traffic Allowance Permitted Next', field: 'trfcAllowancePermittedNext' },
    { headerName: 'Extra Time Next', field: 'extraTimeNext' },
    { headerName: 'Train Distance Next', field: 'trainDistanceNext' },
    { headerName: 'Travel Time Next', field: 'travelTimeNext' },
    { headerName: 'Section Speed Next', field: 'sectionSpeedNext' },
    { headerName: 'Time Saved', field: 'timeSaved' },
    { headerName: 'Speed Gain', field: 'speedGain' },
    { headerName: 'Speed Gain Percentage', field: 'speedGainPercent' },
    { headerName: 'Stoppage Reduce', field: 'stoppageReduced' },
    { headerName: 'Stoppage Time Reduce', field: 'stoppageTimeReduced' },
  ];


  const columnOrder = ['zoneCode', 'dvsnCode', 'runTime', 'stoppageTime', 'stoppageCount', 'accTime', 'decTime', 'enggAllowance', 'enggAllowancePermitted', 'trfcAllowance',
    'trfcAllowancePermitted', 'extraTime', 'trainDistance', 'travelTime', 'sectionSpeed', 'zoneCodeNext', 'dvsnCodeNext', 'runTimeNext', 'stoppageTimeNext',
    'stoppageCountNext', 'accTimeNext', 'decTimeNext', 'enggAllowanceNext', 'enggAllowancePermittedNext', 'trfcAllowanceNext', 'trfcAllowancePermittedNext',
    'extraTimeNext', 'trainDistanceNext', 'travelTimeNext', 'sectionSpeedNext', 'timeSaved', 'speedGain', 'speedGainPercent', 'stoppageReduced', 'stoppageTimeReduced'
  ];

  const reorderedData = rowData.map(item => {
    const reorderedItem = {};
    columnOrder.forEach(key => {
      if (item.hasOwnProperty(key)) {
        reorderedItem[key] = item[key];
      }
    });
    return reorderedItem;
  });


  return (
    <div className="ag-theme-alpine" style={{ height: '650px'}}>
      <div>
        <table>
          <tr> 
            <td > Previous Date</td>
            <td> <Calendar handleDateSelect={handlePrevDateSelect} /> </td>
            <td> Next Date</td>
            <td> <Calendar handleDateSelect={handleNextDateSelect} /> </td>
            <td> <button onClick={handleButtonClick}>Fetch Data</button></td>
            <td>  <PdfFile pdfData={rowData} columnDefinition={columnDefs} columnOrder={columnOrder} fileName="DivisionalSummaryComparision" /></td>
            <td>  <ExportToExcel excelData={reorderedData} fileName="example_data" /></td>
          </tr>
          </table>
      </div>

      {loading ? (
        // Show a progress bar while loading data       
        <progress style={{ width: '100%', height: '30px',}} />
      ) : (
      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        //domLayout='autoHeight'
        autoSizeColumns={true}
        rowSelection="multiple"
      />
      )}
    </div>
  );

};

export default DivisionalSummaryComparision;
