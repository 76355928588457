import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';
import SelectDivision from '../../components/SelectDivision';
import SelectZone from '../../components/SelectZone';
import ExportToExcel from '../../components/ExcelFile';
import PdfFile from '../../components/PdfFile';

const BoardList = () => {

  const [rowData, setRowData] = useState([]);
  const [selectedZone, setSelectedZone] = useState(null);
  const [selectedDivision, setSelectedDivision] = useState(null);

  const handleSelectZone = (selectedZone) => {
    setSelectedZone(selectedZone.value);
  };

  const handleSelectDivision = (selectedDivision) => {
    setSelectedDivision(selectedDivision.value);
  };

  const handleButtonClick = () => {

    if (selectedZone && selectedDivision) {
      // Call the additional API using all three selected values
      axios
        .get(`${process.env.REACT_APP_INFRA_SERVICE_ADDRESS}/boardlist/${selectedZone}/${selectedDivision}`)
        .then((response) => {
          // Set the additional data to state
          setRowData(response.data);
        })
        .catch((error) => {
          console.error('Error fetching additional data:', error);
        });
    }
  };

  const columnDefs = [
    { headerName: 'Zone', field: 'zoneCode' },
    { headerName: 'Division', field: 'dvsnCode' },
    { headerName: 'Board Name', field: 'boardName' },
  ];

  const columnOrder = ['zoneCode', 'dvsnCode', 'boardName'];

  const reorderedData = rowData.map(item => {
    const reorderedItem = {};
    columnOrder.forEach(key => {
      if (item.hasOwnProperty(key)) {
        reorderedItem[key] = item[key];
      }
    });
    return reorderedItem;
  });

  return (
    <div className="ag-theme-alpine" style={{ height: '600px', width: '610px' }}>
      <div>
        <table>
          <tr>
            <td style={{ width: '210px' }}>
              <SelectZone onSelectChange={handleSelectZone} />
            </td>
            <td style={{ width: '210px' }}>
              {selectedZone && (
                <SelectDivision selectedValue={selectedZone} onSelectChange={handleSelectDivision} />
              )}
            </td>
            <td> <button onClick={handleButtonClick}>Fetch Data</button></td>
            <td> <PdfFile pdfData={rowData} columnDefinition={columnDefs} columnOrder={columnOrder} fileName="BoardMaster" /> </td>
            <td> <ExportToExcel excelData={reorderedData} fileName="BoardMaster" /> </td>
          </tr>
        </table>
      </div>

      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        //domLayout='autoHeight'
        autoSizeColumns={true}
        rowSelection="multiple"
      />
    </div>
  );

};

export default BoardList;