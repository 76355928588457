// src/ProgressBar.js
import React from 'react';
import '../styles/StatusBar.css';

const StatusBar = ({ width, backgroundColor,color }) => {
  return (
    <div className="status-bar">
      <div className="status-bar-fill" style={{ width: `${width}%`, backgroundColor,color }}>
        {width}%
      </div>
    </div>
  );
};

export default StatusBar;
