import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';

const DivisionwiseExtraTimeConsumed = () => {
   
    const [rowData, setRowData] = useState([]);

    useEffect(() => {
        // Fetch data from the API
        axios.get(`${process.env.REACT_APP_REPORT_SERVICE_ADDRESS}/DvsnwiseExtraTimeConsumed`)
          .then(response => {
            // Set the fetched data to the state
            setRowData(response.data);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
      }, []);
    

      const columnDefs = [    

        { headerName: 'Train Number', field: 'trainNumber', filter: true},     
        { headerName: 'Zone', field: 'zoneCode', filter: true}, 
        { headerName: 'Division', field: 'dvsnCode', filter: true},
       { headerName: 'Distance', field: 'distance'},
        { headerName: 'Permitted Allowance', field: 'permittedAllowance'},
        { headerName: 'Permitted Allowance Standard', field: 'permittedAllowanceStandered'},
        { headerName: 'Consumed Allowance', field: 'consumedAllowance'},
        { headerName: 'Consumed Allowance Standard', field: 'consumedAllowanceStandered'},
        { headerName: 'Extra Allowance', field: 'exterAllowance'},
        { headerName: 'Extra Allowance Standard', field: 'exterAllowanceStandered'},
    ];
        

  return (
    <div className="ag-theme-alpine" style={{ height: '800px', width: '100%' }}>
        
      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        //domLayout='autoHeight'
        autoSizeColumns={true}
        rowSelection="multiple"
      />
    </div>
  );

};

export default DivisionwiseExtraTimeConsumed;