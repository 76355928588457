import React, { useState, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';
import Calendar from '../../components/Calendar';
import ExportToExcel from '../../components/ExcelFile';
import PdfFile from '../../components/PdfFile';

const TrainServiceSummary = () => {

  const [rowData, setRowData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const gridApiRef = useRef(null);

  const handleDateSelection = (selectedDate) => {

    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const formattedDate = selectedDate.toLocaleDateString('en-GB', options).replace(/\//g, '-');
    // Use the selectedDate in your API call
    console.log("Selected Date:", selectedDate.toISOString().split('T')[0]);
    console.log("Selected Date:", formattedDate);
    // Make your API call here with the selectedDate as an argument

    if (selectedDate) {
      setSelectedDate(formattedDate); // Format the date as YYYY-MM-DD            
    }
  };

  const handleButtonClick = () => {

    setLoading(true);

    console.log(selectedDate);
    // Fetch data from the API
    axios.get(`${process.env.REACT_APP_REPORT_SERVICE_ADDRESS}/getRlywisetypewiseTraincount/${selectedDate}`)
      .then(response => {
        // Set the fetched data to the state
        setRowData(response.data);

        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  };
 

  const columnDefs = [
    { headerName: 'Type', field: 'trainType' },    
    { headerName: 'CR', field: 'trainCountCR' },
    { headerName: 'WR', field: 'trainCountWR' },
    { headerName: 'WCR', field: 'trainCountWCR' },
    { headerName: 'SR', field: 'trainCountSR' },
    { headerName: 'SCR', field: 'trainCountSCR' },
    { headerName: 'SWR', field: 'trainCountSWR' },
    { headerName: 'NCR', field: 'trainCountNCR' },
    { headerName: 'NR', field: 'trainCountNR' },
    { headerName: 'NWR', field: 'trainCountNWR' },
    { headerName: 'NER', field: 'trainCountNER' },
    { headerName: 'KR', field: 'trainCountKR' },
    { headerName: 'ECR', field: 'trainCountECR' },
    { headerName: 'ECOR', field: 'trainCountECOR' },
    { headerName: 'ER', field: 'trainCountER' },
    { headerName: 'NFR', field: 'trainCountNFR' },
    { headerName: 'SECR', field: 'trainCountSECR' },
    { headerName: 'SER', field: 'trainCountSER' },
    { headerName: 'Count', field: 'trainCount' },    
    { headerName: 'Aftd CR', field: 'trainCountAftdCR' },
    { headerName: 'Aftd WR', field: 'trainCountAftdWR' },
    { headerName: 'Aftd WCR', field: 'trainCountAftdWCR' },
    { headerName: 'Aftd SR', field: 'trainCountAftdSR' },
    { headerName: 'Aftd SCR', field: 'trainCountAftdSCR' },
    { headerName: 'Aftd SWR', field: 'trainCountAftdSWR' },
    { headerName: 'Aftd NCR', field: 'trainCountAftdNCR' },
    { headerName: 'Aftd NR', field: 'trainCountAftdNR' },
    { headerName: 'Aftd NWR', field: 'trainCountAftdNWR' },
    { headerName: 'Aftd NER', field: 'trainCountAftdNER' },
    { headerName: 'Aftd KR', field: 'trainCountAftdKR' },
    { headerName: 'Aftd ECR', field: 'trainCountAftdECR' },
    { headerName: 'Aftd ECOR', field: 'trainCountAftdECOR' },
    { headerName: 'Aftd ER', field: 'trainCountAftdER' },
    { headerName: 'Aftd NFR', field: 'trainCountAftdNFR' },
    { headerName: 'Aftd SECR', field: 'trainCountAftdSECR' },
    { headerName: 'Aftd SER', field: 'trainCountAftdSER' },
    { headerName: 'Aftd', field: 'trainCountAftd' },
  ];

  const onGridReady = (params) => {
    gridApiRef.current = params.api;
  };

  const adjustColumnWidths = () => {
    if (gridApiRef.current) {
      const allColumnIds = [];
      const columnDefs = gridApiRef.current.getColumnDefs();
      
      // Get all column ids
      columnDefs.forEach((column) => {
        allColumnIds.push(column.field);
      });
  
      // Iterate over each row to determine the maximum width for each column
      const maxWidths = {};
      rowData.forEach((row) => {
        allColumnIds.forEach((colId) => {
          const value = row[colId] ? String(row[colId]).length : 0;
          maxWidths[colId] = Math.max(maxWidths[colId] || 0, value);
        });
      });
  
      // Update column definitions with adjusted widths
      const updatedColumnDefs = columnDefs.map((column) => {
        const maxWidth = maxWidths[column.field] * 8 + 40; // Adjust based on your font size and padding
        return { ...column, width: maxWidth };
      });
  
      // Set the updated column definitions
      gridApiRef.current.setColumnDefs(updatedColumnDefs);
    }
  };
  

  const gridOptions = {
    getRowHeight: () => 20, // Set a fixed row height of 20 pixels
    // Other grid options...
  };

  const columnOrder = ['trainType', 'trainCount', 'trainCountCR', 'trainCountWR', 'trainCountWCR', 'trainCountSR', 'trainCountSCR', 'trainCountSWR', 'trainCountNCR',
    'trainCountNR', 'trainCountNWR', 'trainCountNER', 'trainCountKR', 'trainCountECR', 'trainCountECOR', 'trainCountER', 'trainCountNFR',
    'trainCountSECR', 'trainCountSER', 'trainCountAftd', 'trainCountAftdCR', 'trainCountAftdWR', 'trainCountAftdWCR', 'trainCountAftdSR',
    'trainCountAftdSCR', 'trainCountAftdSWR', 'trainCountAftdNCR', 'trainCountAftdNR', 'trainCountAftdNWR', 'trainCountAftdNER', 'trainCountAftdKR',
    'trainCountAftdECR', 'trainCountAftdECOR', 'trainCountAftdER', 'trainCountAftdNFR', 'trainCountAftdSECR', 'trainCountAftdSER'
  ];

  const reorderedData = rowData.map(item => {
    const reorderedItem = {};
    columnOrder.forEach(key => {
      if (item.hasOwnProperty(key)) {
        reorderedItem[key] = item[key];
      }
    });
    return reorderedItem;
  });


  return (
    <div className="ag-theme-alpine" style={{ height: '650px'}}>
      <div>
        <table>
          <tr>
            <td> Select Date </td>
            <td> <Calendar handleDateSelect={handleDateSelection} /> </td>
            <td> <button onClick={handleButtonClick}>Fetch Data</button> </td>
            <td>      <PdfFile pdfData={rowData} columnDefinition={columnDefs} columnOrder={columnOrder} fileName="TrainServiceSummary" /> </td>
            <td>      <ExportToExcel excelData={reorderedData} fileName="example_data" /></td>
          </tr>
        </table>
      </div>

      {loading ? (        
        // Show a progress bar while loading data       
        <progress style={{ width: '100%', height: '30px',}} />
      ) : (
      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        //domLayout='autoHeight'
        //autoSizeColumns={true}
        //rowSelection="multiple"
        onGridReady={onGridReady}
        onFirstDataRendered={adjustColumnWidths}
        gridOptions={gridOptions}
      />
      )}
    </div>
  );

};

export default TrainServiceSummary;