import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';
import SelectDivision from '../../components/SelectDivision';
import SelectZone from '../../components/SelectZone';
import SelectBlockSection from '../../components/SelectBlockSection';
import ExportToExcel from '../../components/ExcelFile';
import PdfFile from '../../components/PdfFile';

const BlockSectionLine = () => {
   
    const [rowData, setRowData] = useState([]);
    const [selectedZone, setSelectedZone] = useState(null);
    const [selectedDivision, setSelectedDivision] = useState(null);
    const [selectedBlockSection, setSelectedBlockSection] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleSelectZone = (selectedZone) => {
      console.log("Selected Zone:", selectedZone.value);

      setSelectedZone(selectedZone.value);
    };

    const handleSelectDivision = (selectedDivision) => {
      console.log("Selected Division:", selectedDivision.value);
      setSelectedDivision(selectedDivision.value);
    };

    const handleSelectStation = (selectedBlockSection) => {
      console.log("Selected BlockSection:", selectedBlockSection.value);
      setSelectedBlockSection(selectedBlockSection.value);
    };

    const handleButtonClick = () => {
      setLoading(true);
      if (selectedBlockSection) {
        // Fetch data from the API
        axios.get(`${process.env.REACT_APP_INFRA_SERVICE_ADDRESS}/blocksectionline/${selectedBlockSection}`)
          .then(response => {
            if (response.data === null) {
              // Alert if no data is found
              alert('No data found');
              setLoading(false);
            } else {
            // Set the fetched data to the state
            setRowData(response.data);
            setLoading(false);
            }
          })
          .catch(error => {
            console.error('Error fetching data:', error);
            setLoading(false);
          });
      }
    };
    
    const columnDefs = [        
        { headerName: 'Serial Number', field: 'seqNumber'},
        { headerName: 'Line Number', field: 'lineNumber'},
        { headerName: 'Line Name', field: 'lineName'},
        { headerName: 'Line Length', field: 'lineLength'},
        { headerName: 'Line Category', field: 'lineCategory'},
        { headerName: 'Gauge', field: 'gauge'},
        { headerName: 'Traction', field: 'traction'},
        { headerName: 'Signal Count', field: 'signalCount'},
        { headerName: 'Number of Level Crossing', field: 'levelCrossingCount'},
    ];
        
    const columnOrder = ['seqNumber', 'lineNumber', 'lineName', 'lineLength', 'lineCategory', 'gauge', 'traction', 'signalCount', 'levelCrossingCount'];

    const reorderedData = rowData.map(item => {
      const reorderedItem = {};
      columnOrder.forEach(key => {
        if (item.hasOwnProperty(key)) {
          reorderedItem[key] = item[key];
        }
      });
      return reorderedItem;
    });

  return (
    <div className="ag-theme-alpine" style={{ height: '650px' }}>
        <div>
        <table>
          <tr>
            <td style={{ width: '210px' }}>
              <SelectZone onSelectChange={handleSelectZone} />
            </td>
            <td style={{ width: '210px' }}>
              {selectedZone && <SelectDivision selectedValue={selectedZone} onSelectChange={handleSelectDivision} />}
            </td>
            <td style={{ width: '210px' }}>
              {selectedZone && selectedDivision && <SelectBlockSection selectedValue={selectedZone} selectedValue1={selectedDivision} onSelectChange={handleSelectStation} />}
            </td>
            <td> <button onClick={handleButtonClick}>Fetch Data</button> </td>

            <td> <PdfFile pdfData={rowData} columnDefinition={columnDefs} columnOrder={columnOrder} fileName="StationTimeTable" /></td>

            <td> <ExportToExcel excelData={reorderedData} fileName="example_data" /> </td>
          </tr>
        </table>
      </div>

      {loading ? (
        // Show a progress bar while loading data       
        <progress style={{ width: '100%', height: '30px',}} />
      ) : (
      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        //domLayout='autoHeight'
        autoSizeColumns={true}
        rowSelection="multiple"
      />
      )}
    </div>
  );

};

export default BlockSectionLine;