import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';
import SelectDivision from '../../components/SelectDivision';
import SelectZone from '../../components/SelectZone';
import SelectStation from '../../components/SelectStation';
import ExportToExcel from '../../components/ExcelFile';
import PdfFile from '../../components/PdfFile';

const StationPlatfomDetail = () => {
   
    const [rowData, setRowData] = useState([]);
    const [selectedZone, setSelectedZone] = useState(null);
    const [selectedDivision, setSelectedDivision] = useState(null);
    const [selectedStation, setSelectedStation] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleSelectZone = (selectedZone) => {
      console.log("Selected Zone:", selectedZone.value);

      setSelectedZone(selectedZone.value);
    };

    const handleSelectDivision = (selectedDivision) => {
      console.log("Selected Division:", selectedDivision.value);
      setSelectedDivision(selectedDivision.value);
    };

    const handleSelectStation = (selectedStation) => {
      console.log("Selected Station:", selectedStation.value);
      setSelectedStation(selectedStation.value);
    };

    const handleButtonClick = () => {
      setLoading(true);
      if (selectedStation) {
           // Fetch data from the API
        axios.get(`${process.env.REACT_APP_INFRA_SERVICE_ADDRESS}/sttnplatform/${selectedStation}`)
          .then(response => {
            // Set the fetched data to the state
            setRowData(response.data);
            setLoading(false);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
            setLoading(false);
          });
      }
    };
    

    const columnDefs = [        
        { headerName: 'Station', field: 'sttncode',hide:true},
        { headerName: 'Station Line Sequence', field: 'lineSeq'},
        { headerName: 'Station Line Number', field: 'lineNumber'},
        { headerName: 'Platform Number', field: 'platformNumber'},
        { headerName: 'Platform Length', field: 'platformLength'},
        { headerName: 'Platform Type', field: 'platformType'},
        { headerName: 'Suburban Flag', field: 'suburbanFlag'},
        { headerName: 'Coach Capacity', field: 'capacity'},
        { headerName: 'Facility', field: 'facility'},
        { headerName: 'Start Buffer', field: 'startBuffer'},
        { headerName: 'End Buffer', field: 'endBuffer'},
    ];
       
    const columnOrder = ['sttncode', 'lineSeq', 'lineNumber', 'platformNumber', 'platformLength', 'platformType', 'suburbanFlag', 'capacity', 'facility','startBuffer','endBuffer'];

  const reorderedData = rowData.map(item => {
    const reorderedItem = {};
    columnOrder.forEach(key => {
      if (item.hasOwnProperty(key)) {
        reorderedItem[key] = item[key];
      }
    });
    return reorderedItem;
  });

  return (
    <div className="ag-theme-alpine" style={{ height: '650px'}}>
        <div>
        <table>
          <tr>
            <td style={{ width: '210px' }}>
              <SelectZone onSelectChange={handleSelectZone} />
            </td>
            <td style={{ width: '210px' }}>
              {selectedZone && <SelectDivision selectedValue={selectedZone} onSelectChange={handleSelectDivision} />}
            </td>
            <td style={{ width: '210px' }}>
              {selectedZone && selectedDivision && <SelectStation selectedValue={selectedZone} selectedValue1={selectedDivision} onSelectChange={handleSelectStation} />}
            </td>
            <td> <button onClick={handleButtonClick}>Fetch Data</button> </td>

            <td> <PdfFile pdfData={rowData} columnDefinition={columnDefs} columnOrder={columnOrder} fileName="StationTimeTable" /></td>

            <td> <ExportToExcel excelData={reorderedData} fileName="example_data" /> </td>
          </tr>
        </table>
      </div>    

      {loading ? (
        // Show a progress bar while loading data       
        <progress style={{ width: '100%', height: '30px',}} />
      ) : (
      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        //domLayout='autoHeight'
        autoSizeColumns={true}
        rowSelection="multiple"
      />
      )}
    </div>
  );

};

export default StationPlatfomDetail;