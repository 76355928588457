import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';
import ExportToExcel from '../../components/ExcelFile';
import PdfFile from '../../components/PdfFile';

const LongStoppageZoneSummary = () => {
   
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      setLoading(true);
        // Fetch data from the API
        axios.get(`${process.env.REACT_APP_REPORT_SERVICE_ADDRESS}/LongStpgZoneSummary`)
          .then(response => {
            // Set the fetched data to the state
            setRowData(response.data);
            setLoading(false);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
            setLoading(false);
          });
      }, []);
    

    const columnDefs = [         
        { headerName: 'Zone', field: 'zoneCode'},    
        { headerName: 'Mail/Express Trains', field: 'expressTrains'},
        { headerName: 'Passenger Trains', field: 'passangerTrains'},
        { headerName: 'Total', field: 'trains'},
        { headerName: 'Divisional Breakup', field: 'trainList'},
    ];
        
    const columnOrder = ['zoneCode', 'expressTrains','passangerTrains','trains','expressTrainList','passangerTrainList'];

    const reorderedData = rowData.map(item => {
    const reorderedItem = {};
    columnOrder.forEach(key => {
      if (item.hasOwnProperty(key)) {
        reorderedItem[key] = item[key];
      }
    });
    return reorderedItem;
  });
    
  return (
    <div className="ag-theme-alpine" style={{ height: '650px'}}>

      <div>  
        <td>       
          <PdfFile pdfData={rowData} columnDefinition={columnDefs} columnOrder={columnOrder} fileName="LongStoppageZoneSummary"/>

          <ExportToExcel excelData={reorderedData} fileName="example_data" />
        </td>
      </div>

      {loading ? (        
        // Show a progress bar while loading data       
        <progress style={{ width: '100%', height: '30px',}} />
      ) : (
      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        //domLayout='autoHeight'
        //onGridReady={onGridReady}
        autoSizeColumns={true}
        rowSelection="multiple"
      />
      )}
    </div>
  );

};

export default LongStoppageZoneSummary;