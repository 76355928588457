import React from 'react';
import FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import imageSrc from '../images/excel.bmp'

const ExcelFile = ({ excelData, fileName }) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  return (
    <button variant="contained" onClick={exportToExcel} color="primary" style={{ cursor: "pointer", fontSize: 14 , height:'40px', width:'40px', backgroundColor:'transparent',padding:0,border:'none'}}>
      <img src={imageSrc} alt="" style={{ width: '100%', height: '100%' }}/>
      {/* Export to Excel */}
    </button>
  );
}

export default ExcelFile;