import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';


const MapRouteExceptions = () => {

    const [rowData, setRowData] = useState([]);

    useEffect(() => {
        // Fetch data from the API
        axios.get(`${process.env.REACT_APP_INFRA_SERVICE_ADDRESS}/filterMap`)
          .then(response => {
            // Set the fetched data to the state
            setRowData(response.data);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
      }, []);
  
    

    const columnDefs22 = [
        { headerName: 'Division Code', field: 'dvsnCode' },
        { headerName: 'Division Name', field: 'dvsnName' },
        { headerName: 'Zone Code', field: 'zoneCode' },
      ];
    const columnDefs21 = [
      { headerName: 'Zone Code', field: 'zoneCode' },
      { headerName: 'Division Code', field: 'dvsnCode' },
      { headerName: 'Verify Status', field: 'verifyStatus' },
      { headerName: 'Verify Date', field: 'verifyDate' },
      { headerName: 'Mobile Number (Zone)', field: 'mobileNumberZone' },
      { headerName: 'Mobile Number (Division)', field: 'mobileNumberDvsn' },
      { headerName: 'Mobile Number (Zone Cptm)', field: 'mobileNumberZoneCptm' },
      { headerName: 'Mobile Number (Zone Srdom)', field: 'mobileNumberZoneSrdom' },
      { headerName: 'Verify Status Sttn', field: 'verifyStatusSttn' },
      { headerName: 'Verify Status Blcksctn', field: 'verifyStatusBlcksctn' },
      { headerName: 'Verify Status Section', field: 'verifyStatusSection' },
      { headerName: 'Verify Status Occupancy', field: 'verifyStatusOccupancy' },
      { headerName: 'Verify Status Corridor', field: 'verifyStatusCorridor' },
      { headerName: 'Verify Status Loco Change', field: 'verifyStatusLocoChange' },
      { headerName: 'Verify Status Crew Change', field: 'verifyStatusCrewChange' },
      { headerName: 'Verify Status Revarsal', field: 'verifyStatusRevarsal' },
      { headerName: 'Verify Status Psr', field: 'verifyStatusPsr' },
      { headerName: 'Verify Status Gradient', field: 'verifyStatusGradient' },
      { headerName: 'Verify Status Cureve', field: 'verifyStatusCureve' },
      { headerName: 'Verify Status Route Link', field: 'verifyStatusRouteLink' },
      { headerName: 'Verify Status Train Profile', field: 'verifyStatusTrainProfile' },
      { headerName: 'Verify Status Platform', field: 'verifyStatusPlatform' },
      // Add more column definitions as needed...
    ];
    
  
    
    const columnDefs = [
        { headerName: 'Station Sequence', field: 'sttnSequence' },
        { headerName: 'Station Traffic', field: 'sttnTraffic' },
        { headerName: 'Via Flag', field: 'viaFlag' },
        { headerName: 'Key Station', field: 'keySttn' }
      ];
      


  return (
    <div className="ag-theme-alpine" style={{ height: '1200px', width: '100%' }}>
        {/* <button onClick={togglePanel}>Toggle Panel</button> */}
      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        //domLayout='autoHeight'
        autoSizeColumns={true}
        rowSelection="multiple"
      />
      {/* {isPanelVisible && <PanelContent />} */}
    </div>
  );

};

export default MapRouteExceptions;