import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';

const MapZonalNodes = () => {
   
    const [rowData, setRowData] = useState([]);

    useEffect(() => {
        // Fetch data from the API
        axios.get(`${process.env.REACT_APP_INFRA_SERVICE_ADDRESS}/zonalmap/`)
          .then(response => {
            // Set the fetched data to the state
            setRowData(response.data);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
      }, []);
    

    const columnDefs = [        
        { headerName: 'Zone', field: 'zoneCode', filter: true},
        { headerName: 'Station', field: 'sttnCode'},
        { headerName: 'X coordinate', field: 'xcordinate'},
        { headerName: 'Y coordinate', field: 'ycordinate'},
    ];
        

  return (
    <div className="ag-theme-alpine" style={{ height: '600px', width: '820px' }}>
        <h1>All India Station List with Co-Ordinate</h1>
      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        //domLayout='autoHeight'
        autoSizeColumns={true}
        rowSelection="multiple"
      />
    </div>
  );

};

export default MapZonalNodes;