import React, {useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';
import SelectDivision from '../../components/SelectDivision';
import SelectZone from '../../components/SelectZone';
import SelectBoard from '../../components/SelectBoard';
import ExportToExcel from '../../components/ExcelFile';
import PdfFile from '../../components/PdfFile';

const BoardStation = () => {
   
    const [rowData, setRowData] = useState([]);
    const [selectedZone, setSelectedZone] = useState(null);
    const [selectedDivision, setSelectedDivision] = useState(null);
    const [selectedBoard, setSelectedBoard] = useState(null);

    const handleSelectZone = (selectedZone) => {
      console.log("Selected Zone:", selectedZone.value);
  
      setSelectedZone(selectedZone.value);
    };
  
    const handleSelectDivision = (selectedDivision) => {
      console.log("Selected Division:", selectedDivision.value);
      setSelectedDivision(selectedDivision.value);
    };
  
    const handleSelectSection = (selectedBoard) => {
      console.log("Selected Board:", selectedBoard.value);
      setSelectedBoard(selectedBoard.value);
    };

    const handleButtonClick = () => {

      if (selectedDivision && selectedBoard) {
        console.log(selectedDivision+","+selectedBoard);
        // Fetch data from the API
        axios.get(`${process.env.REACT_APP_INFRA_SERVICE_ADDRESS}/boardstation/${selectedDivision}/${selectedBoard}`)
          .then(response => {
            // Set the fetched data to the state
            setRowData(response.data);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
      }
    };
    

    const columnDefs = [               
        { headerName: 'Division', field: 'dvsnCode',hide:true},
        { headerName: 'Board Name', field: 'boardName',hide:true},
        { headerName: 'Subsection Name', field: 'subsectionName'},
        { headerName: 'Station Sequence', field: 'sectionSeq'},
        { headerName: 'Station Code', field: 'sectionStationSeq'},
        { headerName: 'Display Sequence', field: 'displaySequence'},
        { headerName: 'Display Flag', field: 'displayFlag'},
    ];
        
    const columnOrder = ['dvsnCode', 'boardName', 'subsectionName', 'sectionSeq', 'sectionStationSeq', 'displaySequence','displayFlag'];

  const reorderedData = rowData.map(item => {
    const reorderedItem = {};
    columnOrder.forEach(key => {
      if (item.hasOwnProperty(key)) {
        reorderedItem[key] = item[key];
      }
    });
    return reorderedItem;
  });


  return (
    <div className="ag-theme-alpine" style={{ height: '600px', width: '100%' }}>
        <div>
        <table>
          <tr>
            <td style={{ width: '210px' }}>
              <SelectZone onSelectChange={handleSelectZone} />
            </td>
            <td style={{ width: '210px' }}>
              {selectedZone && <SelectDivision selectedValue={selectedZone} onSelectChange={handleSelectDivision} />}
            </td>
            <td style={{ width: '210px' }}>
              {selectedZone && selectedDivision && <SelectBoard selectedValue={selectedZone} selectedValue1={selectedDivision} onSelectChange={handleSelectSection} />}
            </td>
            <td> <button onClick={handleButtonClick}>Fetch Data</button> </td>

            <td> <PdfFile pdfData={rowData} columnDefinition={columnDefs} columnOrder={columnOrder} fileName="BoardStationList" /></td>

            <td> <ExportToExcel excelData={reorderedData} fileName="BoardStationList" /> </td>
          </tr>
        </table>
      </div>

      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        //domLayout='autoHeight'
        autoSizeColumns={true}
        rowSelection="multiple"
      />
    </div>
  );

};

export default BoardStation;